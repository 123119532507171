import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-screen-capture',
  templateUrl: './screen-capture.component.html',
  styleUrls: ['./screen-capture.component.scss'],
})
export class ScreenCaptureComponent implements OnInit {
  @ViewChild('canvasScreenCapture') canvasRef: ElementRef;
  get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  @ViewChild('videoScreenCapture') videoRef: ElementRef;
  get video(): HTMLVideoElement {
    return this.videoRef.nativeElement;
  }

  @ViewChild('imgScreenCapture') imgRef: ElementRef;
  get img(): HTMLImageElement {
    return this.imgRef.nativeElement;
  }

  @ViewChild('aScreenCapture') aRef: ElementRef;
  get a(): HTMLAnchorElement {
    return this.aRef.nativeElement;
  }

  constructor() {}

  ngOnInit(): void {}

  public screenCapture() {
    // @ts-ignore
    navigator.mediaDevices.getDisplayMedia({ video: true }).then((stream) => {
      console.log('stream', stream);
      this.video.srcObject = stream;

      setTimeout(() => {
        this.canvas.width = this.video.videoWidth;
        this.canvas.height = this.video.videoHeight;
        this.canvas.getContext('2d').drawImage(this.video, 0, 0);

        const dataUrl = this.canvas.toDataURL('image/jpeg');
        this.img.src = dataUrl;

        this.a.href = dataUrl;
        const now = new Date();
        this.a.download = `captura-${now.getFullYear()}-${now.getMonth()}-${now.getDate()}-${now.getHours()}_${now.getMinutes()}.jpeg`;
        this.a.click();
        const tracks = this.videoRef.nativeElement.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
        this.videoRef.nativeElement.srcObject = null;
      }, 3000);
    });
  }
}

import { ParticipantInterface } from '../interface/participant.interface';

export const PARTICIPANT = 'participant';

export interface ParticipantState {
  [PARTICIPANT]: ParticipantInterface;
}

export const initParticipant: ParticipantInterface = {
  participants: [],
  isSettingParticipants: false
};

import { Injectable } from '@angular/core';
import { ResponseError } from 'src/app/interfaces/response-error.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private _router: Router) {}

  public manageApiError(responseError: any) {
    this.showApiErrorOnConsole(responseError);
    if (
      responseError.path?.startsWith(
        '/api/meeting/configuration/room/detail/participants/'
      )
    ) {
      this._router.navigateByUrl('/views/error', {
        state: { error: 'fullRoom' },
      });
    } else {
      this._handleError(responseError.status);
    }
  }

  private showApiErrorOnConsole(responseError: ResponseError) {
    console.error(
      `API error: errorKey=${responseError.errorKey} - time=${responseError.time}
      - errorCode=${responseError.errorCode} - errorData=${responseError.errorData}`
    );
  }

  private _handleError(error: string) {
    if (!error) {
      error = '404';
    }
    this._router.navigateByUrl('/views/error', { state: { error } });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { InvitationsInterface } from 'src/app/store/interface/invitations.interface';
import { selectLanguage } from 'src/app/store/centerResponse/center.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectDataParams } from 'src/app/store/root.selector';
import { ActivatedRoute } from '@angular/router';
import { rootAction } from 'src/app/store/root.action';
import { InviteParticipantsService } from './invite-participants.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { participantAction } from '../../store/participant/participant.action';
import { ToastInterface } from '../../store/interface/toast.interface';
import { Constant } from '../../utils/constant';
import * as participantSelection from 'src/app/store/participant/participant.selector';

@Component({
  selector: 'app-invite-participants-modal',
  templateUrl: './invite-participants-modal.component.html',
  styleUrls: ['./invite-participants-modal.component.scss'],
})
export class InviteParticipantsModalComponent implements OnInit, OnDestroy {
  public form: FormArray;
  public invitations: InvitationsInterface;
  public emails: string[];
  public emailsAux: string[];
  public idRoom: string;
  public language: string;
  private _unsubscriber$: Subject<void> = new Subject();
  public toast: ToastInterface;

  readonly isSettingParticipants$: Observable<boolean> = this._store.select(
    participantSelection.isSettingParticipants
  );

  constructor(
    private _formBuilder: FormBuilder,
    private _store: Store,
    private _activatedRoute: ActivatedRoute,
    private _invite: InviteParticipantsService,
    private _modal: ModalService
  ) {
    this.emails = [];
    this.emailsAux = [];
    this.invitations = { email: [], roomCode: '', lang: '' };
    this.toast = { text: '', type: '', closed: true };
  }

  ngOnInit(): void {
    this._buildForm();
  }

  private _buildForm(): void {
    this.form = this._formBuilder.array([]);
    this.form.push(
      this._formBuilder.group({
        email: [
          '',
          [Validators.required, Validators.pattern(Constant.EMAILS_PATTERN)],
        ],
        admin: [false],
      })
    );
  }

  public submit() {
    if (this.form.valid) {
      this.emails = this.form.value.map((value) => value.email);
      this.invitations.email = this.emails;
      this._store.select(participantSelection.selectParticipant).pipe(takeUntil(this._unsubscriber$)).subscribe(participants => {
        participants.forEach(participant => this.emailsAux.push(participant));
        this.emails.forEach(email => this.emailsAux.push(email));
        this._saveEmails(this.emailsAux);
      });

      this._getIdRoomClient();
      this.invitations.roomCode = this.idRoom;

      this._getLanguage();
      this.invitations.lang = this.language;

      this.toast.text = 'successfulInvitations';
      this.toast.type = 'success';
      this.toast.closed = true;

      this._invite.sendInvitations(this.invitations).subscribe(
        (response) => {
          this._modal.hide();
          this._store.dispatch(rootAction.showToast({ toast: this.toast }));
          this._store.dispatch(participantAction.setParticipantsSuccess());
        },
        (error) => {
          console.error(error);
          this._store.dispatch(participantAction.setParticipantsFail());
        }
      );
    } else {
      /*Object.keys(this.form.controls).forEach((field) => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });*/
      this.form.markAllAsTouched();

      this.toast.text = 'failedInvitations';
      this.toast.type = 'danger';
      this.toast.closed = true;

      this._store.dispatch(rootAction.showToast({ toast: this.toast }));
      this._store.dispatch(participantAction.setParticipantsFail());
    }
  }

  private _saveEmails(participants: string[]): void {
    this._store.dispatch(
      participantAction.setParticipants({
        participants,
      })
    );
  }

  private _getIdRoomClient() {
    this._store
      .select(selectDataParams)
      .pipe(
        filter((res) => res.client !== ''),
        takeUntil(this._unsubscriber$)
      )
      .subscribe((res) => {
        this.idRoom = res.client.substring(res.client.lastIndexOf('/') + 1);
      });
  }

  private _getLanguage(): void {
    this._store
      .select(selectLanguage)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((langs) => {
        if (langs && langs.length > 0) {
          this.language = langs[0].toLowerCase();
        } else {
          this.language = 'es';
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }
}

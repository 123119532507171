import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectDataParams } from 'src/app/store/root.selector';
import { DataParamsInterface } from 'src/app/store/interface/data-params.interface';

@Injectable()
export class PathParamReplaceInterceptor implements HttpInterceptor {
  private dataParams: DataParamsInterface;

  constructor(private _store: Store) {
    this._store
      .select(selectDataParams)
      .subscribe(
        (dataParams: DataParamsInterface) => (this.dataParams = dataParams)
      );
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const httpReq = request.clone({
      url: this.replacePathParamsUrl(request.url),
    });
    return next.handle(httpReq);
  }

  private replacePathParamsUrl(url: string): string {
    return url
      .replace('{groupID}', this.dataParams.groupmeeting)
      .replace('{locationID}', this.dataParams.location)
      .replace('{dataID}', this.dataParams.data)
      .replace('{room}', this.dataParams.room);
  }
}

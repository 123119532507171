<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="logo">
        <app-logo></app-logo>
      </div>
    </div>
  </div>

  <div class="msg mt-4">
    <p>{{ 'mobileAppMessage' | translate }}</p>
    <br/>

    <p>{{ 'alreadyInstalledApp' | translate }}</p>
    <br/>

    <a class="button text-bold" [href]="url | sanitizer" aria-label="acceso">{{ 'accessAppointment' | translate }}</a>
    <br/><br>

    <p>{{ 'notInstalledApp' | translate }}</p>
    <br/>

    <a class="button text-bold" [href]="downloadUrl | sanitizer" aria-label="descarga">{{ 'downloadApp' | translate }}</a>
    <!-- <div class="logoStore" *ngIf="so === androidText">
      <a [class]="class" [href]="url | sanitizer"> </a>
    </div> -->

    <!-- <div class="logoStore" *ngIf="so === iOSText"> -->
      <!--<p class="text-bold">
        {{ 'installedAppMsg' | translate }}
      </p>
      <a [href]="urlJitsi | sanitizer">
        {{ 'openApp' | translate }}
      </a>-->
      <!-- <a [class]="class" [href]="url | sanitizer"> </a>
    </div> -->
  </div>
</div>

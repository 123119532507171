import { createAction, props } from '@ngrx/store';
import { CenterResponseInterface } from '../interface/center-response.interface';

const fetchCenterResponse = createAction('[jisti] fetchCenterResponse');

const setCenterResponse = createAction(
  '[jisti] setCenterResponse',
  props<{ centerResponse: CenterResponseInterface }>()
);

const setLanguages = createAction(
  '[jisti] setLanguages',
  props<{ languages: string[] }>()
);

export const centerAction = {
  fetchCenterResponse,
  setCenterResponse,
  setLanguages,
};

import { Action, createReducer, on } from '@ngrx/store';

import { textAction } from './text.action';
import { TextStorageInterface } from '../interface/text-storage.interface';
import { initTextState } from './text.state';

export const textReducer = createReducer(
  initTextState,
  on(textAction.setAllTexts, (state, { texts }) => ({
    ...state,
    texts,
  })),

  on(textAction.setText, (state, { text }) => ({
    ...state,
    textFilter: text,
  }))
);

export function reducer(
  state: TextStorageInterface | undefined,
  action: Action
) {
  return textReducer(state, action);
}

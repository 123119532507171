import { WsFileInterface } from '../../interfaces/ws-file.interface';

export const WSFILE = 'wsfile';

export interface WsState {
  [WSFILE]: WsFileInterface;
}

export const initWsFile: WsFileInterface = {
  receiver: null,
  inProgress: null,
};

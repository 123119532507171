import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ParamsModal } from '../interface/params-modal';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('component', { read: ViewContainerRef, static: true })
  component: ViewContainerRef;
  @Input() public headTitle: string;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() set body(value: any) {
    this._injectComponent(value);
  }
  @Input() backView: boolean;
  @Input() backViewOpacity: boolean;
  @Input() paramsComponent: ParamsModal[];
  public closeButton: boolean;
  @Input() closeButtonBottom: boolean;
  @Input() textButtonClosed: string;
  @Input() removeDefaultClass: boolean;

  constructor(
    private _factoryResolver: ComponentFactoryResolver,
    private _changeDetRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.closed.emit(false);
  }

  private _injectComponent(component: any): void {
    this.component.clear();
    const compo = this._factoryResolver.resolveComponentFactory(component);
    const compoRef = this.component.createComponent(compo);
    if (this.paramsComponent) {
      for (const param of this.paramsComponent) {
        compoRef.instance[param.key] = param.value;
      }
    }
    this._changeDetRef.detectChanges();
  }

  closeModal() {
    this.closed.emit(true);
  }

  ngOnDestroy(): void {
    this.closed.emit(false);
  }
}

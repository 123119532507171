<div class="cnt-splash" [ngClass]="closeClass ? 'dismiss-splash' : ''">
  <div class="wrapper-content">
    <div class="logo-spash">
      <img alt="Logo consultame" src="assets/images/consultame.svg" />
    </div>
    <div class="text-splash">
      <span>Loading</span>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  </div>
</div>

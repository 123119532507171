import { Injectable } from '@angular/core';
import { GetDataService } from '../../services/get-data/get-data.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { appointmentAction } from './appointment.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Injectable()
export class AppointmentEffect {
  constructor(
    private actions$: Actions,
    private _getDataService: GetDataService,
    private _spinnerService: SpinnerService
  ) {}

  fetchAppointmentData = createEffect(() =>
    this.actions$.pipe(
      ofType(appointmentAction.fetchAppointmentData),
      switchMap((params) => {
        this._spinnerService.show();
        return this._getDataService.getAppointmentData$(params.room).pipe(
          map((res) => {
            this._spinnerService.hide();
            return appointmentAction.setAppointmentData({ appointment: res });
          }),
          catchError((err) => {
            this._spinnerService.hide();
            return throwError(err);
          })
        );
      })
    )
  );
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GetCountParticipantService {
  private _listParticipant: string[];
  constructor() {
    this._listParticipant = [];
  }

  public getAllEmailParticipant(newParticipant: string[]) {
    for (const email of newParticipant) {
      const filter = this._listParticipant.filter((item) => item === email);
      if (filter.length === 0) {
        this._listParticipant.push(email);
      }
    }
    return this._listParticipant;
  }
}

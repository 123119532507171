import { IsGroupalInterface } from '../../interfaces/is-groupal.interface';

export const GROUPAL_APP = 'groupalApp';

export interface GroupalAppState {
  [GROUPAL_APP]: IsGroupalInterface;
}
export const initStateGroupalApp: IsGroupalInterface = {
  inGroup: false,
  profile: '',
  isAccessRandom: null,
};

import { Action, createReducer, on } from '@ngrx/store';
import { initStateCenter } from './center.state';
import { centerAction } from './center.action';
import { CenterResponseInterface } from '../interface/center-response.interface';

export const centerReducer = createReducer(
  initStateCenter,
  on(centerAction.setCenterResponse, (state, { centerResponse }) => ({
    ...state,
    ...centerResponse,
  })),
  on(centerAction.setLanguages, (state, { languages }) => ({
    ...state,
    languages,
  }))
);

export function centerReducers(
  state: CenterResponseInterface | undefined,
  action: Action
) {
  return centerReducer(state, action);
}

import { TextStorageInterface } from '../interface/text-storage.interface';

export const TEXT = 'text';
export interface TextState {
  [TEXT]: TextStorageInterface;
}

export const initTextState: TextStorageInterface = {
  texts: null,
  textFilter: null,
};

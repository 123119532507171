import { ViewContainerRef } from '@angular/core';

export class ToastModel {
  text: string;
  type: string;
  closed: boolean;
  view?: ViewContainerRef;

  constructor() {
    this.text = '';
    this.type = '';
    this.closed = true;
  }
}

import { createAction, props } from '@ngrx/store';
import { TextsInterface } from '../../interfaces/texts-interface';

const fetchAllTexts = createAction(
  '[jitsi] fetchAllTexts',
  props<{ group: string; location: string; lang: string }>()
);

const setAllTexts = createAction(
  '[jitsi] setAllTexts',
  props<{ texts: TextsInterface[] }>()
);

const filterText = createAction('[jitsi] filterText', props<{ key: string }>());

const setText = createAction(
  '[jitsi] setText',
  props<{ text: TextsInterface }>()
);

export const textAction = {
  fetchAllTexts,
  setAllTexts,
  setText,
  filterText,
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizerPipe } from './sanitizer/sanitizer.pipe';
import { ImagePathPipe } from './image-path/image-path.pipe';

@NgModule({
  declarations: [SanitizerPipe, ImagePathPipe],
  exports: [SanitizerPipe, ImagePathPipe],
  imports: [CommonModule],
})
export class PipesModule {}

export const urls = {
  final: {
    initCall: '/meeting/checkIsGroupalRoom/',
    groupalRooms: '/meeting/getGroupalRooms/',
    centerResponse: '/meeting/center/room/{room}',
    appointmentData: '/meeting/data/room/',
    appointmentDataMng:
      '/meeting/group/{groupID}/location/{locationID}/data/{dataID}',
    dataParams: '/meeting/room/',
    totalRooms: '/internal-meeting/count',
    invitations: '/meeting/room/invitations',
    rooms: '/internal-meeting/rooms',
    roomsCreate: '/internal-meeting/room',
    configurationApp:
      '/meeting/configuration/group/{groupID}/location/{locationID}',
    configurationInternalApp: '/internal-meeting/room/',
    accessControl:
      '/meeting/configuration/room/detail/participants/group/{groupID}/location/{locationID}/room/',
    uploadFiles: '/meeting/attachments/participantType/{{typeUser}}/id/',
    configurationDetail: '/meeting/configuration/detail/room/',
    getAllFiles:
      '/meeting/attachments/byRoommgt/participantType/{typeUser}/id/',
    downloadFile:
      '/meeting/attachments/participantType/{userType}/id/{room}/filename/{filename}',
    removeFile:
      '/meeting/attachments/participantType/{userType}/id/{room}/filename/{filename}',
    wsUrl: '/meeting-attachment',
    wsUrlSub: '/topic/roomId/',
    termsConditions: '/text/legal',
    getEmailGuest: '/invitation/getMail/',
    getTexts: '/text/key',
    getAllTexts: '/text/all',
    statistics: '/WebStatistics/navigator/{navigator}/version/{version}',
    login: '/ldap/',
    kickUser: '/invitation/kickUser/{room}'
  },
};

<div class="content-tutorial">
  <div class="tutorial-body">
    <div class="step">
      <p>{{ 'step' | translate }} {{ step + 1 }}</p>
    </div>
    <div class="controls">
      <span
        [ngClass]="{ disableControl: !step }"
        (click)="incrementedDecrementedStep(0)"
        class="material-icons"
      >
        keyboard_arrow_left
      </span>
      <span
        [ngClass]="{ disableControl: step === stepsTutorial - 1 }"
        (click)="incrementedDecrementedStep(1)"
        class="material-icons"
      >
        keyboard_arrow_right
      </span>
    </div>

    <div class="img">
      <div class="img-content">
        <img [src]="imgsTutorial[step]" alt="tutorial" />
      </div>
    </div>

    <div class="text-explicit">
      <p>{{ texts[step] | translate }}</p>
    </div>

    <div class="text-explicit" *ngIf="step === stepsTutorial - 1">
      <p>
        <b>{{ 'tutorialNote' | translate }}</b>
      </p>
    </div>

    <div class="cnt-button">
      <button class="flat" (click)="closeModal()">
        {{ 'close' | translate }}
      </button>
      <button *ngIf="step === stepsTutorial - 1" (click)="reload()">
        {{ 'reload' | translate }}
      </button>
    </div>
  </div>
</div>

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { checkinActions } from 'src/app/store/checkin/checkin.action';
import { environment } from '../../../../environments/environment';
import { rootAction } from '../../../store/root.action';
import { SpinnerBlackService } from '../../../services/spinner-black/spinner-black.service';
import { ModalService } from '../../../services/modal/modal.service';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { takeUntil } from 'rxjs/operators';
import { Constant } from 'src/app/utils/constant';
import { GetConfigurationService } from 'src/app/services/get-configuration/get-configuration.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class JistiApiService implements OnDestroy {
  public options: OptionsJitsi;
  public api: JitsiMeetExternalAPI;

  public videoMuteStatusChanged: Subject<boolean>;
  public videoAvailabilityChanged: Subject<boolean>;
  public micError: Subject<boolean>;
  public audioMuteStatusChanged: Subject<boolean>;
  public cameraError: Subject<boolean>;

  private _isAudioOutput: boolean;

  private isFirefox;
  private isSafari;

  private _unsubscriber$: Subject<void> = new Subject();

  constructor(
    private _store: Store,
    private _spinnerService: SpinnerBlackService,
    private _modalService: ModalService,
    private _appConfig: AppConfigService,
    private dataParamsService: GetConfigurationService,
    private router: Router
  ) {}


  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }

  initJitsiComponent(
    domain: string,
    idRoom: string,
    width: number | string,
    height: number | string,
    hangUpAdmin: EventEmitter<boolean>,
    participantLeft: EventEmitter<boolean>,
    participantJoined: EventEmitter<boolean>,
    divClass: any,
    token: string,
    isFirefox: boolean,
    isSafari: boolean,
    groupMeeting: string,
    name?: string,
    subject?: string
  ): Observable<boolean> {
    this.isFirefox = isFirefox;
    this.isSafari = isSafari;
    this.options = {
      roomName: idRoom,
      width,
      height,
      parentNode: undefined,
      jwt: environment.mock ? '' : token ?? '',
      configOverwrite: {
        defaultLanguage: 'es',
        disableRemoteMute: true,
        disablePolls: true
      },
      interfaceConfigOverwrite: {
        SHOW_CHROME_EXTENSION_BANNER: false,
        ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 15000,
        TOOLBAR_BUTTONS: ['microphone', 'camera', 'chat', 'desktop'],
      },
    };

    this.options.parentNode = divClass;
    this.api = new JitsiMeetExternalAPI(domain, this.options);

    this.api.addEventListener('participantRoleChanged', (event) => {
      if (event.role === 'moderator' && this.router.url.indexOf('admin') !== -1) {
        this._updateState(Constant.ADMIN_STATES.OPEN, this.options.roomName);
      }
      if (groupMeeting === 'SUEZ' || groupMeeting === 'suez') {
        this.api.executeCommand('toggleLobby', true);
      }
    });

    if (name) {
      setTimeout(() => {
        this.api.executeCommand('displayName', name);
      }, 2000);
    }

    if (subject) {
      this.api.executeCommand('subject', subject);
    }

    this._addListenerDeviceListChanged();
    this._addListenerAudioAvailabilityChange();
    this._addListenerVideoAvailabilityChange();

    // eventos para verificar diferentes cambios en la videconferencia, aún no sabemos si van a ser útiles
    this._addListenerParticipantLeft(participantLeft);
    this._addListenerParticipantJoined(participantJoined);
    this._addListenerAudioMutation();

    this._eventVideoAvailable();
    this._eventAudioAvailable();
    this._eventIsDeviceChangeAvailable();
    this._eventDeviceListAvailable();

    this._addListenerDevicesAvailable();
    this._addListenerVideoMuteChange();
    this._addListenerAudioMuteStatusChanged();
    this._addListenerReadyToClose(hangUpAdmin);
    return of(true);
  }

  private _updateState(state: string, idRoom: string) {
    this.dataParamsService
      .patchDataParams(escape(idRoom), state)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((data) => {});
  }

  private _eventIsDeviceChangeAvailable() {
    this.api.isDeviceChangeAvailable().then((data) => {});
  }

  private _eventVideoAvailable(): void {
    this.api.isVideoAvailable().then((data) => {});
  }

  private _eventAudioAvailable(): void {
    this.api.isAudioAvailable().then((data) => {});
  }

  private _eventDeviceListAvailable(): void {
    this.api.isDeviceListAvailable().then((data) => {});
  }

  private _addListenerDeviceListChanged() {
    this.api.addEventListener('deviceListChanged', (data: Devices) => {});
  }
  private _addListenerVideoMuteChange(): void {
    this.api.addEventListener(
      'videoMuteStatusChanged',
      (data: { muted: boolean }) => {}
    );
  }

  private _addListenerParticipantLeft(hangUp: EventEmitter<boolean>): void {
    this.api.addEventListener('participantLeft', (data) => {
      console.log(data);
      hangUp.emit(true);
    });
  }

  private _addListenerReadyToClose(hangUp: EventEmitter<boolean>): void {
    this.api.addEventListener('readyToClose', (data) => {
      hangUp.emit(true);
    });
  }

  private _addListenerParticipantJoined(joined: EventEmitter<boolean>): void {
    this.api.addEventListener('participantJoined', (data) => {
      joined.emit(true);
      this._store.dispatch(
        checkinActions.setIsDoctorOnCall({ isDoctorOnCall: true })
      );
    });
  }

  private _addListenerAudioMutation(): void {
    this.api.addEventListener(
      'audioMuteStatusChanged',
      (data: { muted: boolean }) => {}
    );
  }

  private _addListenerAudioMuteStatusChanged(): void {
    this.api.addEventListener('audioMuteStatusChanged ', (data: any) => {});
  }

  private _addListenerVideoAvailabilityChange(): void {
    this.api.addEventListener('videoAvailabilityChanged ', (data: any) => {
      this.api.getAvailableDevices().then((data2: any) => {
        this.api.getCurrentDevices().then((currentDevices: any) => {
          // console.log('*** emit devices videoAvailabilityChanged - 1', data2);
          // console.log('*** emit devices videoAvailabilityChanged - 2', currentDevices);
          this.emitDevices(data2, currentDevices);
        });
      });
    });
  }

  private emitDevices(data: any, currentDevices: any) {
    let result = data;
    if (!result.devices) {
      result = {
        devices: data,
      };
    }

    let audioInputDevices = [];
    let audioOutputDevices = [];
    let videoInputDevices = [];
    if (currentDevices.audioInput) {
      result.devices.audioInput.forEach((d: Device) => {
        const dAux = Object.assign({}, d);
        dAux.selected = d.deviceId === currentDevices.audioInput.deviceId;
        audioInputDevices.push(dAux);
      });
    } else {
      audioInputDevices = [...result.devices.audioInput];
    }
    if (currentDevices.audioOutput) {
      result.devices.audioOutput.forEach((d: Device) => {
        const dAux = Object.assign({}, d);
        dAux.selected = d.deviceId === currentDevices.audioOutput.deviceId;
        audioOutputDevices.push(dAux);
      });
    } else {
      audioOutputDevices = [...result.devices.audioOutput];
    }
    if (currentDevices.videoInput) {
      result.devices.videoInput.forEach((d: Device) => {
        const dAux = Object.assign({}, d);
        dAux.selected = d.deviceId === currentDevices.videoInput.deviceId;
        videoInputDevices.push(dAux);
      });
    } else {
      videoInputDevices = [...result.devices.videoInput];
    }

    const value = {
      devices: {
        audioInput: audioInputDevices.filter(
          (d: Device) => d.deviceId !== '' && d.label !== ''
        ),
        videoInput: videoInputDevices.filter(
          (d: Device) => d.deviceId !== '' && d.label !== ''
        ),
        audioOutput: audioOutputDevices.filter(
          (d: Device) => d.deviceId !== '' && d.label !== ''
        ),
        // audioInput: result.devices.audioInput.filter((d: Device) => d.deviceId !== ''),
        // videoInput: result.devices.videoInput.filter((d: Device) => d.deviceId !== ''),
        // audioOutput: result.devices.audioOutput.filter((d: Device) => d.deviceId !== '')
      },
    };
    this.verifyPermissions(value);
    // verifyPermission.emit(value);
  }

  private _addListenerAudioAvailabilityChange(): void {
    this.api.addEventListener('audioAvailabilityChanged', (data: any) => {
      this.api.getAvailableDevices().then((data2: any) => {
        this.api.getCurrentDevices().then((currentDevices) => {
          // console.log('*** emit devices audioAvailabilityChanged - 1', data2);
          // console.log('*** emit devices audioAvailabilityChanged - 2', currentDevices);
          if (
            data2?.audioInput?.length === 0 ||
            (data2?.audioOutput?.length === 0 &&
              !this.isFirefox &&
              !this.isSafari)
          ) {
            this._isAudioOutput = false;
            this._store.dispatch(
              rootAction.setShowError({
                showError: true,
              })
            );
          } else {
            this._isAudioOutput = true;
          }
          this.emitDevices(data2, currentDevices);
        });
      });
    });
  }

  private _addListenerDevicesAvailable(): void {
    this.api.getAvailableDevices().then((data: any) => {
      // this.api.getAvailableDevices().then((data2: any) => {
      //
      // });
    });
  }

  /**
   * Mutes / unmutes the audio for the local participant
   */
  public toggleAudio(): void {
    this.api.executeCommand('toggleAudio');
  }

  /**
   * Mutes / unmutes the audio for the local participant
   */
  public toggleVideo(): void {
    this.api.executeCommand('toggleVideo');
  }

  /*public activeAudio() {
    if (this.api) {
      this.api.isAudioMuted().then((data) => {
        if (data) {
          this.toggleAudio();
        }
      });
    }
  }*/

  public activeVideo() {
    if (this.api) {
      this.api.isVideoMuted().then((data) => {
        if (data) {
          this.toggleVideo();
        }
      });
    }
  }

  public setAudioOutputDevice(label: string, deviceId: string) {
    this.api.setAudioOutputDevice(label, deviceId).then((data) => {});
  }

  public setAudioInputDevice(label: string, deviceId: string) {
    this.api.setAudioInputDevice(label, deviceId).then((data) => {});
  }

  public setVideoInputDevice(label: string, deviceId: string) {
    this.api.setVideoInputDevice(label, deviceId).then((data) => {});
  }

  public verifyPermissions(data: Devices) {
    // seteo en la store de los dispositivos disponibles
    if (data && data.devices) {
      this._setAudioInputDevices(data.devices.audioInput as []);
      this._setAudioOutputDevices(data.devices.audioOutput as []);
      this._setVideoDevices(data.devices.videoInput as []);
    }

    // si vienen con un label es que tienen permisos
    let audioPermission = data.devices.audioInput
      ? data.devices.audioInput.filter((d: Device) => d.label !== '').length > 0
      : false;
    const videoPermission = data.devices.videoInput
      ? data.devices.videoInput.filter((d: Device) => d.label !== '').length > 0
      : false;

    if (this.isFirefox) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((succ) => {
          console.log(succ);
        })
        .catch((err) => {
          audioPermission = false;
          this._setPermissionMicrophoneStore(audioPermission);
        });
    }
    // seteamos permisos en la store
    this._setPermissionVideoStore(videoPermission);
    this._setPermissionMicrophoneStore(audioPermission);

    // si no se han dado permisos de audio registramos el onchange (sólo funciona para firefox)
    if (!audioPermission) {
      // onchange
      this.checkPermisionsMicrophone();
    } else {
      // this.verifyMutedAudio = true;
      // this.activeAudio();
    }

    if (!videoPermission) {
      // onchange
      this.checkPermisionsVideo();
    } else {
      // this.verifyMutedVideo = true;
      this.activeVideo();
    }
  }

  private _setAudioInputDevices(devices: []) {
    if (devices && devices.length > 0) {
      this._store.dispatch(
        rootAction.setAudioInputDevices({
          audioInputDevices: devices,
        })
      );
    }
  }

  private _setAudioOutputDevices(devices: []) {
    if (devices && devices.length > 0) {
      this._store.dispatch(
        rootAction.setAudioOutputDevices({
          audioOutputDevices: devices,
        })
      );
    }
  }

  private _setVideoDevices(devices: []) {
    if (devices && devices.length > 0) {
      this._store.dispatch(
        rootAction.setVideoDevices({
          videoDevices: devices,
        })
      );
    }
  }

  private _setPermissionVideoStore(permission: boolean) {
    this._store.dispatch(
      rootAction.setPermisionCamera({
        permission: {
          permission,
        },
      })
    );

    if (permission) {
      this.activeVideo();
    }
    this._spinnerService.hide();
    this._modalService.hide();
  }

  private _setPermissionMicrophoneStore(permission: boolean) {
    this._store.dispatch(
      rootAction.setPermisionMicrophone({
        permission: {
          permission,
        },
      })
    );

    if (permission) {
      // this.activeAudio();
      if (!this._isAudioOutput) {
        this._store.dispatch(
          rootAction.setShowError({
            showError: true,
          })
        );
      } else {
        this._store.dispatch(
          rootAction.setShowError({
            showError: false,
          })
        );
      }
    } else {
      this._store.dispatch(
        rootAction.setShowError({
          showError: true,
        })
      );
    }

    this._spinnerService.hide();
    this._modalService.hide();
  }

  public checkPermisionsMicrophone() {
    if (!this.isFirefox) {
      navigator.permissions
        .query({ name: 'microphone' })
        .then((permision) => {
          if (!permision.onchange) {
            permision.onchange = (data) => {
              // this.verifyMutedAudio = data.isTrusted;
              if (data.isTrusted) {
                // this.activeAudio();
                this._setPermissionMicrophoneStore(true);
              }
            };
          }
        })
        .catch(() => console.error('no microphone permission'));
    }
  }

  public checkPermisionsVideo(): void {
    if (!this.isFirefox) {
      navigator.permissions
        .query({ name: 'camera' })
        .then((permision) => {
          if (!permision.onchange) {
            permision.onchange = (data) => {
              // this.verifyMutedVideo = data.isTrusted;
              if (data.isTrusted) {
                this.activeVideo();
                this._setPermissionVideoStore(true);
              }
            };
          }
        })
        .catch(() => console.error('no video permission'));
    }
  }

  public hangUp() {
    this.api.executeCommand('hangup');
  }

  public dispose() {
    this.api?.dispose();
  }
}

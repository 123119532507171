import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private configuration;

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    return this.http
      .get('/assets/config-params.json')
      .toPromise()
      .then((config) => {
        this.configuration = config;
        return true;
      });
  }

  getConfig() {
    return this.configuration;
  }
}

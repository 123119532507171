import { AppointmentDateInterface } from '../../interfaces/appointment-date.interface';
import { AppointmentDataModel } from '../../model/AppointmentData.model';

export const APPOINTMENT = 'appointment';

export interface AppointmentState {
  [APPOINTMENT]: AppointmentDateInterface;
}

export const initStateAppointment: AppointmentDateInterface = new AppointmentDataModel();

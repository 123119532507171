import { createAction, props } from '@ngrx/store';
import { ConfigAppInterface } from '../interface/config-app.interface';
import { IsGroupalInterface } from '../../interfaces/is-groupal.interface';

const fetchIsGroupal = createAction(
  '[jitsi] fetchIsGrooupal',
  props<{ room: string }>()
);

const setIsGroupal = createAction(
  '[jitsi] setIsGrooupal',
  props<{ data: IsGroupalInterface }>()
);

export const isGroupalActions = {
  fetchIsGroupal,
  setIsGroupal,
};

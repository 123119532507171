import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { Constant } from '../../utils/constant';

@Component({
  selector: 'app-form-mails',
  templateUrl: './form-mails.component.html',
  styleUrls: ['./form-mails.component.scss'],
})
export class FormMailsComponent implements OnInit {
  public emails: number[];
  @Input() public invitations: FormArray;
  @Input() public adminController: boolean;
  public contador: number;

  constructor(private _formBuilder: FormBuilder) {
    this.emails = [1];
    this.contador = 1;
  }

  ngOnInit(): void {
    console.log('*** invitations', this.invitations);
  }

  public getFromControlInvitation(
    index: number,
    field: string
  ): AbstractControl {
    return (this.invitations.controls[index] as FormGroup).controls[field];
  }

  public removeOtherAdminsInvitation(item: number) {
    this.invitations.controls.forEach((index, value) => {
      if (value !== item) {
        (this.invitations.controls[value] as FormGroup).setValue({
          email: (this.invitations.controls[value] as FormGroup).value.email,
          admin: false,
        });
      }
    });
  }

  public addInputInvitation() {
    this.invitations.push(
      this._formBuilder.group({
        email: ['', [Validators.pattern(Constant.EMAILS_PATTERN)]],
        admin: [false],
      })
    );
  }

  public removeInputInvitation(index): void {
    this.invitations.removeAt(index);
  }
}

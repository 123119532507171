import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigAppInterface } from '../interface/config-app.interface';
import { CONFIG_APP, ConfigAppState } from './config.state';

export const selectConfigApp = createFeatureSelector<
  ConfigAppState,
  ConfigAppInterface
>(CONFIG_APP);

export const selectTelephones = createSelector(
  selectConfigApp,
  (state) => state.formattedPhones
);

export const selectDetail = createSelector(
  selectConfigApp,
  (state) => state.detail
);

export const selectTemplate = createSelector(
  selectConfigApp,
  (state) => state.template
);

export const selectConditions = createSelector(
  selectConfigApp,
  (state) => state.conditions
);

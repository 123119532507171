import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectBrowser } from '../../store/root.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Constant } from '../../utils/constant';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-answer-notification',
  templateUrl: './answer-notification.component.html',
  styleUrls: ['./answer-notification.component.scss'],
})
export class AnswerNotificationComponent implements OnInit {
  private _unsubscribe$: Subject<string> = new Subject<string>();
  public language: string;
  public imageLanguage: string;
  public texts: string[];
  public step: number;
  public stepsTutorial: number;
  public imgsTutorial: string[];

  constructor(private _store: Store, private _modalService: ModalService) {
    this.step = 0;
    this.stepsTutorial = 0;
    this.imageLanguage = '';
    this.imgsTutorial = [];
    this.texts = [];
  }

  ngOnInit(): void {
    this._getLanguage();
    this._getBrowser();
  }
  private _getBrowser(): void {
    this._store
      .select(selectBrowser)
      .pipe(
        filter((res) => res !== ''),
        takeUntil(this._unsubscribe$)
      )
      .subscribe((browser) => {
        switch (browser) {
          case Constant.BROWSER.SA:
            this.stepsTutorial = 5;
            this.imgsTutorial = Constant[this.imageLanguage].SA;
            this.texts = Constant.NOTIFICATION_TUTORIAL_STEPS.SA;
            break;
          case Constant.BROWSER.OP:
            this.stepsTutorial = 3;
            this.imgsTutorial = Constant[this.imageLanguage].OP;
            this.texts = Constant.NOTIFICATION_TUTORIAL_STEPS.OP;
            break;
          case Constant.BROWSER.CH:
            this.stepsTutorial = 3;
            this.imgsTutorial = Constant[this.imageLanguage].CH;
            this.texts = Constant.NOTIFICATION_TUTORIAL_STEPS.CH;
            break;
          case Constant.BROWSER.FF:
            this.stepsTutorial = 3;
            this.imgsTutorial = Constant[this.imageLanguage].FF;
            this.texts = Constant.NOTIFICATION_TUTORIAL_STEPS.FF;
            break;
          case Constant.BROWSER.ED:
          default:
            this.stepsTutorial = 2;
            this.imgsTutorial = Constant[this.imageLanguage].ED;
            this.texts = Constant.NOTIFICATION_TUTORIAL_STEPS.ED;
            break;
        }
      });
  }

  private _getLanguage() {
    this.language = localStorage.getItem('lang').toUpperCase();
    this.imageLanguage = 'NOTIFICATION_IMAGES_' + this.language;
  }

  public incrementedDecrementedStep(step: number) {
    if (step) {
      if (this.step !== this.stepsTutorial - 1) {
        this.step++;
      }
    } else if (this.step) {
      this.step--;
    }
  }

  public reload() {
    location.reload();
  }

  public closeModal() {
    this._modalService.hide();
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { initStateConfigApp } from './config.state';
import { configAppActions } from './config.action';
import { ConfigAppInterface } from '../interface/config-app.interface';

const reducer = createReducer(
  initStateConfigApp,
  on(configAppActions.setData, (state, { data }) => ({
    ...state,
    ...data,
  })),
  on(configAppActions.setInternalDataDetail, (state, { data }) => ({
    ...state,
    detail: data,
  })),
  on(configAppActions.setConditions, (state, { accepted }) => ({
    ...state,
    conditions: accepted,
  }))
);

export function configAppReducer(
  state: ConfigAppInterface | undefined,
  action: Action
) {
  return reducer(state, action);
}

<ul class="call-button-list">
  <li *ngFor="let button of floatButton">
    <button type="button" [class]="button.class" (click)="clickOnButton(button)" >
      <img [src]="button.icon | imagePath" alt="Imágen botón">
      <div class="badge" *ngIf="button.badge">
        <span class="badge-number">{{ button.badge }}</span>
      </div>

    </button>
    <div class="tooltip-button">{{button.tooltip | translate}}</div>
  </li>
</ul>

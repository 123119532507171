export class CenterResponseModel {
  public logo: string;
  public color: string;
  public languages: string[];

  constructor() {
    this.color = '#f1ebeb';
    this.languages = [];
    this.logo = '';
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../../utils/constant';
import { urls } from '../../utils/urls';
import { Observable } from 'rxjs';
import { HeadersService } from '../headers/headers.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(
    private _http: HttpClient,
    private _headersService: HeadersService,
  ) {}

  public sendBrowserInfo(browser: string, version: string): Observable<any> {
    const headers = this._headersService.getHeaders();
    const url = urls.final.statistics
      .replace('{navigator}', browser)
      .replace('{version}', version);
    return this._http.post("/api" + url, null, {
      headers,
    });
  }
}

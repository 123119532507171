
<div class="square-outer" >
    <h2>{{'appointmentInfo' | translate}}</h2>
      <p>
        {{ 'appointmentInfoMssg1' | translate}} <span>{{ getNamePerson$() | async }}</span>
        {{ 'appointmentInfoMssg2' | translate}} <span>{{ appointmentDate | date: 'shortTime' }}</span>
        {{ 'appointmentInfoMssg3' | translate}} <span>{{ appointmentDate | date: 'dd/MM/yyyy' }}</span>

      </p>
</div>
<app-contact-phones ></app-contact-phones>

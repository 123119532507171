import { Action, createReducer, on } from '@ngrx/store';
import { initStateGroupalApp } from './groupal.state';
import { isGroupalActions } from './groupal.action';
import { IsGroupalInterface } from '../../interfaces/is-groupal.interface';

const reducer = createReducer(
  initStateGroupalApp,
  on(isGroupalActions.setIsGroupal, (state, { data }) => ({
    ...state,
    ...data,
  }))
);
export function isGroupalAppReducer(
  state: IsGroupalInterface | undefined,
  action: Action
) {
  return reducer(state, action);
}

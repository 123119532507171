import { ConfigAppInterface } from '../interface/config-app.interface';

export const CONFIG_APP = 'configApp';

export interface ConfigAppState {
  [CONFIG_APP]: ConfigAppInterface;
}

export const initStateConfigApp: ConfigAppInterface = {
  conditions: false,
  invitations: [{ admin: false, email: '' }],
  detail: null,
  telephones: [],
  formattedPhones: '',
  template: {
    id: 0,
    backgroundColor: null,
    logo: null,
  },
};

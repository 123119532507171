import { createFeatureSelector, createSelector } from '@ngrx/store';

import { GROUPAL_APP, GroupalAppState } from './groupal.state';
import { IsGroupalInterface } from '../../interfaces/is-groupal.interface';

export const selectIsGroupalApp = createFeatureSelector<
  GroupalAppState,
  IsGroupalInterface
>(GROUPAL_APP);

export const selectGroupalProfile = createSelector(
  selectIsGroupalApp,
  (state) => state.profile
);

export const selectIsGroupal = createSelector(
  selectIsGroupalApp,
  (state) => state.inGroup
);

export const selectGroupal = createSelector(
  selectIsGroupalApp,
  (state) => state
);

export const selectIsAccessRandom = createSelector(
  selectIsGroupalApp,
  (state) => state.isAccessRandom
);

import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { InternalConfigurationInterface } from '../../interfaces/internal-configuration.interface';
import { GetDataService } from '../../services/get-data/get-data.service';
import { ConfigAppInterface } from '../interface/config-app.interface';
import { configAppActions } from './config.action';

@Injectable()
export class ConfigAppEffect {
  constructor(
    private actions$: Actions,
    private _getDataService: GetDataService
  ) {}

  fetchConfigApp = createEffect(() =>
    this.actions$.pipe(
      ofType(configAppActions.fetchConfigApp),
      switchMap(({}) => {
        return this._getDataService.getConfigurationApp$().pipe(
          map((res: ConfigAppInterface) => {
            res.formattedPhones = res.telephones?.reduce(
              (contactPhones, telephone, index) =>
                contactPhones
                  .concat(telephone.ext)
                  .concat(' ')
                  .concat(telephone.number)
                  .concat(index !== res.telephones.length - 1 ? ', ' : ''),
              ''
            );
            return configAppActions.setData({ data: res });
          }),
          catchError((err) => {
            return throwError(err);
          })
        );
      })
    )
  );

  loadConditions = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => {
        const conditions = localStorage.getItem('conditions') ?? false;
        return configAppActions.setConditions({
          accepted: conditions ? true : false,
        });
      })
    )
  );

  setConditions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configAppActions.setConditions),
        tap(({ accepted }) => {
          accepted
            ? localStorage.setItem('conditions', 'true')
            : localStorage.removeItem('conditions');
        })
      ),
    { dispatch: false }
  );

  fetchInternalDetailConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(configAppActions.fetchInternalDetailConfig),
      switchMap((params) => {
        return this._getDataService.getConfigurationInternal$(params.room).pipe(
          map((res: InternalConfigurationInterface) => {
            return configAppActions.setInternalDataDetail({
              data: res.configuration.detail,
            });
          }),
          catchError((err) => {
            return throwError(err);
          })
        );
      })
    )
  );
}

import { DataParamsInterface } from '../interface/data-params.interface';
import { DataParamsModel } from '../model/DataParams.model';

export const RANDOMPARTICIPANT = 'randomParticipant';

export interface RandomParticipantState {
  [RANDOMPARTICIPANT]: DataParamsInterface;
}

export const initRandomParticipant: RandomParticipantState = {
  randomParticipant: new DataParamsModel(),
};

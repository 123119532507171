import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APPOINTMENT, AppointmentState } from './appointment.state';
import { AppointmentDateInterface } from '../../interfaces/appointment-date.interface';

export const selectAppointment = createFeatureSelector<
  AppointmentState,
  AppointmentDateInterface
>(APPOINTMENT);

export const selectAppointmentDate = createSelector(
  selectAppointment,
  (state) => state.appointmentDate
);

export const selectAppointmentObj = createSelector(
  selectAppointment,
  (state) => state.appointmentDate
);

export const selectAppointmentName = createSelector(
  selectAppointment,
  (state) => state.name
);

export const selectAppointmentServiceName = createSelector(
  selectAppointment,
  (state) => state.serviceName
);

export const selectAppointmentLastAppDatCall = createSelector(
  selectAppointment,
  (state) => state.lastAppointmentDateCalled
);

export const selectAppointmentAverage = createSelector(
  selectAppointment,
  (state) => state.averageWaitingTime
);

export const selectAppointmentPollUrl = createSelector(
  selectAppointment,
  (state) => state.pollUrl
);

export const selectAppointmentProofUrl = createSelector(
  selectAppointment,
  (state) => state.proofUrl
);

export const selectAppointmentProof = createSelector(
  selectAppointment,
  (state) => state.proof
);

export const selectAppointmentAdvanceTime = createSelector(
  selectAppointment,
  (state) => state.advanceTime
);

export const selectAppointmentDelayTime = createSelector(
  selectAppointment,
  (state) => state.delayTime
);

export const selectAppointmentScreen = createSelector(
  selectAppointment,
  (state) => state.screenInformation
);

import { CenterResponseInterface } from '../interface/center-response.interface';
import { CenterResponseModel } from '../model/CenterResponse.model';

export const CENTER = 'center';

export interface CenterState {
  [CENTER]: CenterResponseInterface;
}

export const initStateCenter: CenterResponseInterface = new CenterResponseModel();

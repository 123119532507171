import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { isGroupalActions } from './groupal.action';
import { IsGroupalService } from '../../services/is-groupal/is-groupal.service';
import { IsGroupalInterface } from '../../interfaces/is-groupal.interface';

@Injectable()
export class IsGroupalAppEffect {
  constructor(
    private actions$: Actions,
    private _isGroupalService: IsGroupalService
  ) {}

  fetchInternalDetailConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(isGroupalActions.fetchIsGroupal),
      switchMap((params) => {
        return this._isGroupalService.getIsGroupal$(params.room).pipe(
          map((res: IsGroupalInterface) => {
            return isGroupalActions.setIsGroupal({
              data: res,
            });
          }),
          catchError((err) => {
            return throwError(err);
          })
        );
      })
    )
  );
}

import { Renderer2, RendererFactory2 } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class Spinner {
  private readonly _render: Renderer2;
  private _cntSpinner: any;
  private _unsubcribe$: Subject<void> = new Subject<void>();

  constructor(
    renderFactory: RendererFactory2,
    private _cntSpinnerClass: string,
    private _spinnerClass: string
  ) {
    this._render = renderFactory.createRenderer(document.body, null);
  }
  public show(textParam: string = null): void {
    if (!this._cntSpinner) {
      this._cntSpinner = this._render.createElement('div');
      this._render.addClass(this._cntSpinner, this._cntSpinnerClass);

      const divSpinner = this._render.createElement('div');
      this._render.addClass(divSpinner, this._spinnerClass);
      this._render.appendChild(this._cntSpinner, divSpinner);

      if (textParam) {
        const spanText = this._render.createElement('span');
        const text = this._render.createText(textParam);
        this._render.appendChild(spanText, text);
        this._render.appendChild(this._cntSpinner, spanText);
      }
      this._render.appendChild(document.body, this._cntSpinner);
    }
  }

  public hide(): void {
    if (this._cntSpinner) {
      this._render.addClass(this._cntSpinner, 'closeSpinner');
      timer(250)
        .pipe(takeUntil(this._unsubcribe$))
        .subscribe(() => {
          this._render.removeChild(document.body, this._cntSpinner);
          this._unsubcribe$.next();
          // this._unsubcribe$.unsubscribe();
        });
    }
  }
}

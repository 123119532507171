import { Component, Input, OnInit } from '@angular/core';
import { DataTurnInterface } from '../../interfaces/data-turn.interface';

@Component({
  selector: 'app-turns-table',
  templateUrl: './turns-table.component.html',
  styleUrls: ['./turns-table.component.scss'],
})
export class TurnsTableComponent implements OnInit {
  @Input() dataTurn: DataTurnInterface[];
  constructor() {}

  ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { Constant } from '../../utils/constant';

@Injectable({
  providedIn: 'root',
})
export class TransformFilesService {
  constructor() {}

  public transformFilesStringToUrlDownload(
    file: string,
    type: string = null
  ): string {
    const byteChart = atob(file);
    const byteNumber = new Array(byteChart.length);
    for (let i = 0; i < byteChart.length; i++) {
      byteNumber[i] = byteChart.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumber);
    const blob = new Blob([byteArray], {
      type: type ?? Constant.TYPE_FILE_TEXT,
    });
    return URL.createObjectURL(blob);
  }
}

<div class="cnt-participant">

  <div class="row" *ngFor="let item of getAllParticipants; index as i">

    <div class="col-12">
      <span class="name">{{item.dataResponse.name}}</span>
    </div>

    <div class="col-12">
      <div class="action" *ngIf="item.state === 'ready' && item.configurationDetail.userReady">
        <button class="col-6 float-right" (click)="updateState(item)">{{'readyParticipant' | translate}}</button>
      </div>

      <div class="action" *ngIf="!item.configurationDetail.userReady && !item.configurationDetail.userProblem">
        <b class="float-right">{{'noReadyParticipant' | translate}}</b>
      </div>


      <div class="action" *ngIf="item.configurationDetail.userProblem">
        <b class="float-right">{{'problemParticipant' | translate}}</b>
      </div>

      <div class="action" *ngIf="item.state === 'wait' && item.configurationDetail.userReady">
        <button class="col-6 float-right warning" (click)="updateState(item)"> {{'onWaitCall' | translate}}</button>
      </div>

      <div class="action" *ngIf="item.state === 'open' && item.configurationDetail.userReady">
        <button class="col-6 float-right success" (click)="updateState(item)">{{'onCourseCall' | translate}}</button>
      </div>
    </div>
  </div>
</div>

import { createAction, props } from '@ngrx/store';

const setReceiverWsFile = createAction(
  '[jitsi] receiver a file share',
  props<{ receiver: number | boolean }>()
);

const setProgressWsFile = createAction(
  '[jitsi] receiver a file share',
  props<{ inProgress: number | boolean }>()
);

export const wsFileAction = {
  setReceiverWsFile,
  setProgressWsFile,
};

import { NgModule } from '@angular/core';
import { VideocamComponent } from './videocam/videocam.component';
import { LogoComponent } from './logo/logo.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { TutorialsModalComponent } from './tutorials-modal/tutorials-modal.component';
import { DevicesSelectorComponent } from './devices-selector/devices-selector.component';
import { InfoWaitingComponent } from './info-waiting/info-waiting.component';
import { BrowserNotSupportedMsgComponent } from './browser-not-supported-msg/browser-not-supported-msg.component';
import { JistiApiService } from './service/jisti-api/jisti-api.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { InviteParticipantsModalComponent } from './invite-participants-modal/invite-participants-modal.component';
import { BrowserDesktopUnsupportedComponent } from './browser-desktop-unsupported/browser-desktop-unsupported.component';
import { FormMailsComponent } from './form-mails/form-mails.component';
import { InviteParticipantsService } from './invite-participants-modal/invite-participants.service';
import { ButtonFloatComponent } from './button-float/button-float.component';
import { WaitingRoomMessageComponent } from './waiting-room-message/waiting-room-message.component';
import { RouterModule } from '@angular/router';
import { ListParticipantComponent } from './list-participant/list-participant.component';
import { ScreenCaptureComponent } from './screen-capture/screen-capture.component';
import { TurnsTableComponent } from './turns-table/turns-table.component';
import { AlertPermissionsComponent } from './alert-permissions/alert-permissions.component';
import { ContactPhonesComponent } from './contact-phones/contact-phones.component';
import { SelfVideoComponent } from './self-video/self-video.component';
import { AnswerNotificationComponent } from './answer-notification/answer-notification.component';
import { SelectParticipantComponent } from './select-participant/select-participant.component';
import { SplashComponent } from './splash/splash.component';
import { ShareFilesComponent } from './share-files/share-files.component';
import { DragAndDropComponent } from './drag-and-drop/drag-and-drop.component';
import { KickUserComponent } from './kick-user/kick-user.component';

@NgModule({
  declarations: [
    VideocamComponent,
    LogoComponent,
    LanguageSelectorComponent,
    TutorialsModalComponent,
    DevicesSelectorComponent,
    InfoWaitingComponent,
    BrowserNotSupportedMsgComponent,
    InviteParticipantsModalComponent,
    BrowserDesktopUnsupportedComponent,
    FormMailsComponent,
    ButtonFloatComponent,
    WaitingRoomMessageComponent,
    ListParticipantComponent,
    ScreenCaptureComponent,
    TurnsTableComponent,
    AlertPermissionsComponent,
    ContactPhonesComponent,
    SelfVideoComponent,
    AnswerNotificationComponent,
    SelectParticipantComponent,
    SplashComponent,
    ShareFilesComponent,
    DragAndDropComponent,
    KickUserComponent,
  ],
  exports: [
    VideocamComponent,
    LogoComponent,
    LanguageSelectorComponent,
    TutorialsModalComponent,
    DevicesSelectorComponent,
    InfoWaitingComponent,
    BrowserNotSupportedMsgComponent,
    InviteParticipantsModalComponent,
    BrowserDesktopUnsupportedComponent,
    FormMailsComponent,
    ButtonFloatComponent,
    WaitingRoomMessageComponent,
    ScreenCaptureComponent,
    TurnsTableComponent,
    AlertPermissionsComponent,
    ContactPhonesComponent,
    SelfVideoComponent,
    AnswerNotificationComponent,
    SplashComponent,
    ShareFilesComponent,
  ],
  providers: [JistiApiService, InviteParticipantsService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    RouterModule,
  ],
})
export class ComponentsModule {}

<div class="tv-turns">
  <div class="tv-turns-screen">
    <h3>{{ 'lastCalls' | translate }}</h3>
    <table>
      <thead>
        <tr>
          <th>{{ 'turn' | translate }}</th>
          <th>{{ 'callHour' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dataTurn">
          <td>{{ item.turn }}</td>
          <td>{{ item.hour | date: 'HH:mm' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

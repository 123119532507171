import { Action, createReducer, on } from '@ngrx/store';
import { initWsFile } from './ws.state';
import { wsFileAction } from './ws.action';
import { WsFileInterface } from '../../interfaces/ws-file.interface';

export const wsFileReducer = createReducer(
  initWsFile,
  on(wsFileAction.setReceiverWsFile, (state, { receiver }) => ({
    ...state,
    ...{ receiver },
  })),
  on(wsFileAction.setProgressWsFile, (state, { inProgress }) => ({
    ...state,
    ...{ inProgress },
  }))
);

export function wsFileReducers(
  state: WsFileInterface | undefined,
  action: Action
) {
  return wsFileReducer(state, action);
}

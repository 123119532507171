import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectParticipant } from '../../store/participant/participant.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GetCountParticipantService } from './get-count-participant.service';

@Component({
  selector: 'app-list-participant',
  templateUrl: './list-participant.component.html',
  styleUrls: ['./list-participant.component.scss'],
})
export class ListParticipantComponent implements OnInit, OnDestroy {
  public participants: string[];
  private _unsubcribe$: Subject<void>;
  constructor(
    private _store: Store,
    private _getCountParticipantService: GetCountParticipantService
  ) {
    this._unsubcribe$ = new Subject<void>();
  }

  ngOnInit(): void {
    this._getParticipant();
  }

  private _getParticipant(): void {
    this._store
      .select(selectParticipant)
      .pipe(
        takeUntil(this._unsubcribe$),
        filter((res) => res.length > 0)
      )
      .subscribe(
        (res) =>
          (this.participants = this._getCountParticipantService.getAllEmailParticipant(
            res
          ))
      );
  }

  ngOnDestroy(): void {
    this._unsubcribe$.unsubscribe();
  }
}

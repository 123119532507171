import { CheckinInterface } from '../interface/checkin.interface';

export const CHECKIN = 'checkin';

export interface CheckinState {
  [CHECKIN]: CheckinInterface;
}

export const initStateCheckin: CheckinInterface = {
  isCheckinCompleted: false,
  isCheckinProblems: false,
  isDoctorOnCall: false,
  isDisabledCheckinButton: false,
};

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TEXT, TextState } from './text.state';
import { TextStorageInterface } from '../interface/text-storage.interface';

export const selectRootText = createFeatureSelector<
  TextState,
  TextStorageInterface
>(TEXT);

export const selectAllText = createSelector(
  selectRootText,
  (state) => state.texts
);

export const selectText = createSelector(
  selectRootText,
  (state) => state.textFilter
);

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InvitationsInterface } from 'src/app/store/interface/invitations.interface';
import { Observable } from 'rxjs';
import { urls } from 'src/app/utils/urls';
import { HeadersService } from 'src/app/services/headers/headers.service';

@Injectable()
export class InviteParticipantsService {
  constructor(
    private _http: HttpClient,
    private _headersService: HeadersService,
  ) {}

  public sendInvitations(invitations: InvitationsInterface): Observable<any> {
    const headers = this._headersService.getHeaders();
    return this._http.post(
      "/api" + urls.final.invitations,
      invitations,
      { headers }
    );
  }
}

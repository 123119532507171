import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { RoomService } from 'src/app/services/room/room.service';
import { selectParticipant } from 'src/app/store/participant/participant.selector';
import { selectRoomMgt } from 'src/app/store/root.selector';
import { GetCountParticipantService } from '../list-participant/get-count-participant.service';

@Component({
  selector: 'app-kick-user',
  templateUrl: './kick-user.component.html',
  styleUrls: ['./kick-user.component.scss']
})
export class KickUserComponent implements OnInit, OnDestroy {
  public participants: string[];
  private idRoomMgt: string;
  private _unsubcribe$: Subject<void>;

  constructor(
    private _store: Store,
    private _getCountParticipantService: GetCountParticipantService,
    private _roomService: RoomService
  ) {
    this._unsubcribe$ = new Subject<void>();
  }

  ngOnInit(): void {
    this._getParticipants();
    this._getIdRoomMgt();
  }

  ngOnDestroy(): void {
    this._unsubcribe$.unsubscribe();
  }

  private _getParticipants(): void {
    this._store
      .select(selectParticipant)
      .pipe(
        takeUntil(this._unsubcribe$),
        filter((res) => res.length > 0)
      )
      .subscribe(
        (res) =>
          (this.participants = this._getCountParticipantService.getAllEmailParticipant(
            res
          ))
      );
  }

  private _getIdRoomMgt(): void {
    this._store
      .select(selectRoomMgt)
      .pipe(
        takeUntil(this._unsubcribe$),
        filter((res) => res !== '')
      )
      .subscribe((idRoom: string) => {
        this.idRoomMgt = idRoom;
      });
  }

  public kickUser(email: string): void {
    this._roomService.kickUser(this.idRoomMgt, email).subscribe();
  }
}


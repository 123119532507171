import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { SplashComponent } from '../../components/splash/splash.component';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SplashService {
  private _splash: ComponentFactory<SplashComponent>;
  private _splashRef: ComponentRef<SplashComponent>;

  private static _getComponentRootNode(
    componentRef: ComponentRef<any>
  ): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
  }
  constructor(
    private _injector: Injector,
    private _factoryResolver: ComponentFactoryResolver,
    private _applicationRef: ApplicationRef
  ) {}

  public showSplash(): void {
    if (!this._splashRef) {
      this._splash = this._factoryResolver.resolveComponentFactory(
        SplashComponent
      );
      this._splashRef = this._splash.create(this._injector);

      this._applicationRef.attachView(this._splashRef.hostView);
      this._applicationRef.components[0].location.nativeElement.appendChild(
        SplashService._getComponentRootNode(this._splashRef)
      );
      this._splashRef.instance.closeClass = false;
    }
  }

  public hideSplash(): void {
    if (this._splashRef) {
      this._splashRef.instance.closeClass = true;
      timer(2000).subscribe(() => {
        if (this._splashRef?.hostView) {
          this._applicationRef.detachView(this._splashRef.hostView);
          this._splashRef.destroy();
          this._splashRef = null;
        }
      });
    }
  }
}

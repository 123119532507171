export const Constant = {
  EMPTY_STRING: '',
  PRE_MSG: ['preMessages_1', 'preMessages_3'],
  ACCEPT_BUTTON: 'acceptButton',
  CONFIG_TITLE: 'configTitle',
  CONFIG_MSG: 'configMsg',
  SHOW_TUTORIAL: 'showTutorial',
  TUTORIAL_HEADER: 'tutorialHeader',
  TUTORIAL_FOOTER: 'tutorialFooter',
  SHOW_BUTTON_INTRUCTIONS: [true, true, true, false],
  ID_ROOM: 'idRoom',
  GROUP: 'group',
  LOCATION: 'location',
  ADMIN_STATES: {
    READY: 'ready',
    OPEN: 'open',
    CLOSED: 'closed',
    STAND_BY: 'wait',
  },
  HASH_ADMIN: '&-&',
  BROWSER: {
    OP: 'OPR',
    CH: 'Chrome',
    iOSCH: 'CriOS',
    SA: 'Safari',
    FF: 'Firefox',
    IE: 'MSIE',
    ED: 'Edg',
    OLD_ED: 'OLD_Edg',
    UNK: 'unknown',
  },
  TYPE_USER: {
    ATTENDANT: 'ATTENDANT',
    GUEST: 'GUEST',
    ORGANIZER: 'ORGANIZER',
  },
  SO: {
    IOS: '(iP',
    IOS_TEXT: 'IOS',
    ANDROID: 'Android',
    DESKTOP: 'Desktop',
  },
  PERMISSION: {
    GRANTED: 'granted',
    DENY: 'denied',
  },
  NO_FISICAL_CAMERA: 'Requested device not found',
  NO_START_VIDEO: 'Could not start video source',
  FF_START_VIDEO_FAILED: 'Starting video failed',
  TUTORIAL_IMG_ES: {
    OP: [
      '../../../assets/images/Opera1ES.png',
      '../../../assets/images/Opera2ES.png',
    ],
    CH: [
      '../../../assets/images/Chrome1ES.png',
      '../../../assets/images/Chrome2ES.png',
      '../../../assets/images/Chrome3ES.png',
    ],
    FF: [
      '../../../assets/images/Firefox1ES.png',
      '../../../assets/images/Firefox2ES.png',
    ],
    ED: [
      '../../../assets/images/Edge1ES.png',
      '../../../assets/images/Edge2ES.png',
      '../../../assets/images/Edge3ES.png',
    ],
    SA: [
      '../../../assets/images/Safari1ES.png',
      '../../../assets/images/Safari2ES.png',
      '../../../assets/images/Safari3ES.png',
      '../../../assets/images/Safari4ES.png',
      '../../../assets/images/Safari5ES.png',
      '../../../assets/images/Safari6ES.png',
      '../../../assets/images/Safari7ES.png',
      '../../../assets/images/Safari8ES.png',
    ],
  },
  TUTORIAL_IMG_EN: {
    OP: [
      '../../../assets/images/Opera1EN.png',
      '../../../assets/images/Opera2EN.png',
    ],
    CH: [
      '../../../assets/images/Chrome1EN.png',
      '../../../assets/images/Chrome2EN.png',
      '../../../assets/images/Chrome3EN.png',
    ],
    FF: [
      '../../../assets/images/Firefox1EN.png',
      '../../../assets/images/Firefox2EN.png',
    ],
    ED: [
      '../../../assets/images/Edge1EN.png',
      '../../../assets/images/Edge2EN.png',
      '../../../assets/images/Edge3EN.png',
    ],
    SA: [
      '../../../assets/images/Safari1EN.png',
      '../../../assets/images/Safari2EN.png',
      '../../../assets/images/Safari3EN.png',
      '../../../assets/images/Safari4EN.png',
      '../../../assets/images/Safari5EN.png',
      '../../../assets/images/Safari6EN.png',
      '../../../assets/images/Safari7EN.png',
      '../../../assets/images/Safari8EN.png',
    ],
  },
  TUTORIAL_IMG_GL: {
    OP: [
      '../../../assets/images/Opera1ES.png',
      '../../../assets/images/Opera2ES.png',
    ],
    CH: [
      '../../../assets/images/Chrome1ES.png',
      '../../../assets/images/Chrome2ES.png',
      '../../../assets/images/Chrome3ES.png',
    ],
    FF: [
      '../../../assets/images/Firefox1GL.png',
      '../../../assets/images/Firefox2GL.png',
    ],
    ED: [
      '../../../assets/images/Edge1GL.png',
      '../../../assets/images/Edge2GL.png',
      '../../../assets/images/Edge3GL.png',
    ],
    SA: [
      '../../../assets/images/Safari1ES.png',
      '../../../assets/images/Safari2ES.png',
      '../../../assets/images/Safari3ES.png',
      '../../../assets/images/Safari4ES.png',
      '../../../assets/images/Safari5ES.png',
      '../../../assets/images/Safari6ES.png',
      '../../../assets/images/Safari7ES.png',
      '../../../assets/images/Safari8ES.png',
    ],
  },
  TUTORIAL_STEPS: {
    OP: ['tutorialStepsOP_1', 'tutorialStepsOP_2'],
    CH: ['tutorialStepsCH_1', 'tutorialStepsCH_2', 'tutorialStepsCH_3'],
    FF: ['tutorialStepsFF_1', 'tutorialStepsFF_2'],
    ED: ['tutorialStepsCH_1', 'tutorialStepsCH_2', 'tutorialStepsCH_3'],
    SA: [
      'tutorialStepsSA_1',
      'tutorialStepsSA_2',
      'tutorialStepsSA_3',
      'tutorialStepsSA_4',
      'tutorialStepsSA_5',
      'tutorialStepsSA_6',
      'tutorialStepsSA_7',
      'tutorialStepsSA_8',
    ],
  },
  TUTORIAL_NOTE: {
    CH: 'tutorialNoteCH',
    ED: 'tutorialNoteCH',
  },
  NOTIFICATION_IMAGES_ES: {
    OP: [
      'assets/images/notificationOpera1ES.png',
      'assets/images/notificationOpera2ES.png',
      'assets/images/notificationOpera3ES.png',
    ],
    CH: [
      'assets/images/notificationChrome1ES.png',
      'assets/images/notificationChrome2ES.png',
      'assets/images/notificationChrome3ES.png',
    ],
    FF: [
      'assets/images/notificationFirefox1ES.png',
      'assets/images/notificationFirefox2ES.png',
      'assets/images/notificationFirefox3ES.png',
    ],
    ED: [
      'assets/images/notificationEdge1ES.png',
      'assets/images/notificationEdge2ES.png',
    ],
    SA: [
      'assets/images/notificationSafari1ES.png',
      'assets/images/notificationSafari2ES.png',
      'assets/images/notificationSafari3ES.png',
      'assets/images/notificationSafari4ES.png',
      'assets/images/notificationSafari5ES.png',
    ],
  },
  NOTIFICATION_IMAGES_EN: {
    OP: [
      'assets/images/notificationOpera1EN.png',
      'assets/images/notificationOpera2EN.png',
      'assets/images/notificationOpera3EN.png',
    ],
    CH: [
      'assets/images/notificationChrome1EN.png',
      'assets/images/notificationChrome2EN.png',
      'assets/images/notificationChrome3EN.png',
    ],
    FF: [
      'assets/images/notificationFirefox1EN.png',
      'assets/images/notificationFirefox2EN.png',
      'assets/images/notificationFirefox3EN.png',
    ],
    ED: [
      'assets/images/notificationEdge1EN.png',
      'assets/images/notificationEdge2EN.png',
    ],
    SA: [
      'assets/images/notificationSafari1EN.png',
      'assets/images/notificationSafari2EN.png',
      'assets/images/notificationSafari3EN.png',
      'assets/images/notificationSafari4EN.png',
      'assets/images/notificationSafari5EN.png',
    ],
  },
  NOTIFICATION_IMAGES_GL: {
    OP: [
      'assets/images/notificationOpera1ES.png',
      'assets/images/notificationOpera2ES.png',
      'assets/images/notificationOpera3ES.png',
    ],
    CH: [
      'assets/images/notificationChrome1ES.png',
      'assets/images/notificationChrome2ES.png',
      'assets/images/notificationChrome3ES.png',
    ],
    FF: [
      'assets/images/notificationFirefox1GL.png',
      'assets/images/notificationFirefox2GL.png',
      'assets/images/notificationFirefox3GL.png',
    ],
    ED: [
      'assets/images/notificationEdge1GL.png',
      'assets/images/notificationEdge2GL.png',
    ],
    SA: [
      'assets/images/notificationSafari1ES.png',
      'assets/images/notificationSafari2ES.png',
      'assets/images/notificationSafari3ES.png',
      'assets/images/notificationSafari4ES.png',
      'assets/images/notificationSafari5ES.png',
    ],
  },
  NOTIFICATION_TUTORIAL_STEPS: {
    OP: [
      'notificationTutorialStepsOP_1',
      'notificationTutorialStepsOP_2',
      'notificationTutorialStepsOP_3',
    ],
    CH: [
      'notificationTutorialStepsCH_1',
      'notificationTutorialStepsCH_2',
      'notificationTutorialStepsCH_3',
    ],
    FF: [
      'notificationTutorialStepsFF_1',
      'notificationTutorialStepsFF_2',
      'notificationTutorialStepsFF_3',
    ],
    ED: ['notificationTutorialStepsED_1', 'notificationTutorialStepsED_2'],
    SA: [
      'notificationTutorialStepsSA_1',
      'notificationTutorialStepsSA_2',
      'notificationTutorialStepsSA_3',
      'notificationTutorialStepsSA_4',
      'notificationTutorialStepsSA_5',
    ],
  },
  NOT_CAMERA_MSG: 'notCameraMsg',
  NOT_CAMERA_BUTTON: 'notCameraButton',
  CONFIG_OK_MSG: 'configOkMsg',
  SPEAKERS: 'speakers',
  MICROPHONE: 'microphone',
  CAMERA: 'camera',
  CHECKIN: 'CHECKIN',
  ERROR_CODE: 'errorCode',
  PROBLEMS: 'ERROR',
  PTE_CHECKIN: 'PTE_CHECKIN',
  ERROR_404_MSG: 'routeNotFound',
  ERROR_403_MSG: 'accessForbidden',
  ERROR_500_MSG: 'error500',
  EXPIRED_APPOINTMENT: 'expiredAppointment',
  EXPIRED_APPOINTMENT_INTERNAL: 'expiredAppointmentInternal',
  CLOSE_APPOINTMENT: 'closeAppointment',
  APPOINTMENT_NOT_AVAILABLE: 'AppointmentNotAvailable',
  APPOINTMENT_NOT_AVAILABLE2: 'AppointmentNotAvailable2',
  APPOINTMENT_NOT_AVAILABLE_INTERNAL2: 'AppointmentNotAvailableInternal2',
  APPOINTMENT_NOT_AVAILABLE3: 'AppointmentNotAvailable3',
  APPOINTMENT_NOT_AVAILABLE4: 'AppointmentNotAvailable4',
  APPOINTMENT_NOT_AVAILABLE_INTERNAL4: 'AppointmentNotAvailableInternal4',
  READY_MESSAGE1: 'readyMessage1',
  READY_MESSAGE2: 'readyMessage2',
  FILTER1: '3D5F$',
  FILTER2: 'RTS5',
  HOUR_MIN_DATE_FORMAT: 'dd/MM/yyyy-HH:mm',
  HOUR_FORMAT: 'HH:mm',
  NOT_SUPPORTED_BROWSER: 'notSupportedBrowser',
  NOT_FULLY_SUPPORTED: 'notFullySupported',
  NOT_SUPPORTED_WEBRTC: 'notSupportedWebRTC',
  UPLOAD_FILE_KEY: 'upload_file',
  DOWNLOAD_FILE_KEY: 'download_file',
  INVITE_PARTICIPANTS_KEY: 'invite_participants',
  UPLOAD_FILES_KEY: 'upload_files',
  END_CALL_KEY: 'end_call',
  KICK_USER: 'kickUser',
  SCREEN_CAPTURE: 'screen_capture',
  DOWNLOAD_PROOF_KEY: 'download_proof',
  CLIENT: 'client',
  ADMIN: 'admin',
  AVIABLE_LANGUAGES: ['es', 'gl', 'en'],
  LOGO_URL: 'assets/logo.png',
  BTN_CONFIG_INITIAL_STEP: 'btnConfigInitialStep',
  BTN_CONFIG_NEXT_STEP: 'btnConfigNextStep',
  BTN_CONFIG_FINAL_STEP: 'btnConfigFinalStep',
  PREFIX_IMG_64: 'data:image/jpeg;base64,',
  PATH_LOGO_DEFAULT: 'assets/images/consultame_client.png',
  TYPE_FILE_PDF: 'application/pdf',
  TYPE_FILE_TEXT: 'text/plain',
  TYPE_IMG_JPEG: 'image/jpeg',
  FULL_ROOM_MSG: 'fullRoomMsg',
  EMAILS_PATTERN:
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  UNIT_BYTE: 1024,
  FILE_MAX: 'file_size_max',
  FILE_EXTENSION_FAIL: 'file_ext_fail',
  DIMENSION_JITSI: '100%',
  TEAMS: 'TEAMS',
  FORMAT_DATE_ROOMS_GENERATOR: 'YYYY-MM-DD HH:mm:ss',
  PROVIDER_INTERNAL: 'INTERNAL',
  NUMBER_ELEMENT_BY_PAGE: 5,
  USER: 'user',
  GUEST: 'guest',
  PRINT: 'print',
  JITSI: 'JITSI',
  SELECT_PARTICPANT: 'select_participant',
  UPLOAD_IN_PROGRESS: 'UPLOAD_IN_PROGRESS',
  UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',
  DELETED_FILE: 'DELETED_FILE',
  UPLOAD_IN_PROGRESS_MSG: 'isSharedFile',
  HEADERS_CSV: {
    ES: [
      'Nombre de la sala',
      'Administrador de la sala',
      'Fecha cita',
      'Link del administrador',
      'Link del usuario',
      'Tipo de sala',
    ],
    EN: [
      'Room name',
      'Room admin',
      'Appointment date',
      'Admin link',
      'User link',
      'Room type',
    ],
    GL: [
      'Nome da sala',
      'Administrador da sala',
      'Data cita',
      'Link del administrador',
      'Link del usuario',
      'Tipo de sala',
    ],
  },
  VERSIONS: {
    CHROME: {
      MIN: '83.0.4103.96',
      MAX: '98.0.4758.81',
    },
    FIREFOX: {
      MIN: '77.0',
      MAX: '96.0.3',
    },
    EDGE: {
      MIN: '79.0.309.65',
      MAX: '97.0.1072.76',
    },
    SAFARI: {
      MIN: '13.1.2',
      MAX: '15.3',
    },
    OPERA: {
      MIN: '68.0',
      MAX: '83.0.4254.27',
    },
  },
};

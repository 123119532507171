<div class="row device-card-row">
  <div class="col-lg-4">
    <div
      class="tutorial_device-card"
      [ngClass]="{
        'phantom-style':
          (tutorialStep != 1 && tutorialStep != 0) || problemsClicked === 2,
        'tutorial_device-card-fail':
          !permisionMicro.permission || problemsClicked == 1
      }"
    >
      <div class="device-card_header">
        <div class="device-card_header_title">
          <img src="assets/images/i-headset.svg" alt="auriculares" />
          <h3>
            {{ isTestRoom ? getText('speakers') : (speakers | translate) }}
          </h3>
        </div>
        <img
          src="assets/images/i-check.svg"
          *ngIf="permisionMicro.permission && problemsClicked != 1"
          alt="check"
        />
        <img
          src="assets/images/i-cross.svg"
          *ngIf="!permisionMicro.permission || problemsClicked == 1"
          alt="cross"
        />
      </div>
      <ng-template [ngIf]="!problemsClicked">
        <select
          id="speakers"
          class="custom-select mb-3"
          (change)="changeSpeakers($event.target.value)"
          *ngIf="speakersDevices.length > 0 && !isFirefox && !isSafari"
          [disabled]="isFirefox || isSafari"
        >
          <option
            *ngFor="let item of speakersDevices; let i = index"
            [value]="item.deviceId"
            [selected]="selectedSpeakers === item.deviceId"
          >
            {{ item.label }}
          </option>
        </select>
        <span *ngIf="tutorialStep == 1">
          <audio controls class="audio-player">
            <source src="/assets/audio/speakersTest.ogg" type="audio/ogg" />
            <source src="/assets/audio/speakersTest.mp3" type="audio/mpeg" />
          </audio>
          <button class="card-btn" (click)="nextStepTutorial()">
            OK
          </button>
          <button class="btn-secondary card-btn" (click)="showProblems(1)">
            {{ isTestRoom ? getText('noSound') : ('noSound' | translate) }}
          </button>
        </span>
      </ng-template>
    </div>
  </div>

  <div class="col-lg-4">
    <div
      class="tutorial_device-card"
      [ngClass]="{
        'phantom-style':
          (tutorialStep != 2 && tutorialStep != 0) || problemsClicked === 1,
        'tutorial_device-card-fail':
          !permisionMicro.permission || problemsClicked == 2
      }"
    >
      <div class="device-card_header">
        <div class="device-card_header_title">
          <img src="assets/images/i-mic.svg" alt="Micrófono"/>
          <h3>
            {{ isTestRoom ? getText('microphone') : (microphone | translate) }}
          </h3>
        </div>
        <img
          src="assets/images/i-check.svg"
          *ngIf="permisionMicro.permission && problemsClicked != 2"
          alt="check"
        />
        <img
          src="assets/images/i-cross.svg"
          *ngIf="!permisionMicro.permission || problemsClicked == 2"
          alt="cross"
        />
      </div>
      <ng-template [ngIf]="!problemsClicked">
        <select
          id="microphone"
          class="custom-select mb-3"
          [ngClass]="{ disabled: isFirefox || isSafari }"
          (change)="changeMicrophone($event.target.value)"
          *ngIf="microphoneDevices.length > 0 && !isFirefox && !isSafari"
          [disabled]="isFirefox || isSafari"
        >
          <option
            *ngFor="let item of microphoneDevices; let i = index"
            [value]="item.deviceId"
            [selected]="selectedMicrophone === item.deviceId"
          >
            {{ item.label }}
          </option>
        </select>
        <span *ngIf="tutorialStep == 2">
          <div class="recorder">
            <div class="waveform">
              <canvas #canvasWave class="js-canvas waveaudio__canvas"></canvas>
            </div>
          </div>

          <button class="card-btn" (click)="nextStepTutorial()">
            OK
          </button>
          <button class="btn-secondary card-btn" (click)="showProblems(2)">
            {{
              isTestRoom ? getText('notDetected') : ('notDetected' | translate)
            }}
          </button>
        </span>
      </ng-template>
    </div>
  </div>

  <div class="col-lg-4">
    <div
      class="tutorial_device-card"
      [ngClass]="{ 'phantom-style': tutorialStep != 3 && tutorialStep != 0 }"
    >
      <div class="device-card_header">
        <div class="device-card_header_title">
          <img src="assets/images/i-cam.svg" alt="Cámara" />
          <h3>
            {{ isTestRoom ? getText('camera') : (camera | translate) }}
          </h3>
        </div>
        <img
          src="assets/images/i-check.svg"
          *ngIf="permisionCamera.permission"
          alt="check"
        />
        <img
          src="assets/images/i-cross.svg"
          *ngIf="!permisionCamera.permission"
          alt="cross"
        />
      </div>
      <ng-template [ngIf]="!problemsClicked">
        <select
          id="camera"
          class="custom-select mb-3"
          [ngClass]="{ disabled: isFirefox }"
          (change)="changeCamera($event.target.value)"
          *ngIf="cameraDevices.length > 0 && !isFirefox"
          [disabled]="isFirefox"
        >
          <option
            *ngFor="let item of cameraDevices; let i = index"
            [value]="item.deviceId"
            [selected]="selectedCamera === item.deviceId"
          >
            {{ item.label }}
          </option>
        </select>
        <div *ngIf="tutorialStep == 3" class="text-center">
          <img
            src="assets/images/no-video.png"
            *ngIf="!permisionCamera.permission"
          />
          <video
            class="card-video"
            autoplay
            #videoTutorial
            width="100%"
            *ngIf="permisionCamera.permission"
          ></video>
          <button
            class="card-btn"
            (click)="nextStepTutorial()"
            [disabled]="!permisionCamera.permission"
          >
            OK
          </button>
          <button
            *ngIf="!permisionCamera.permission || cameraDevices.length < 1"
            class="btn-secondary card-btn"
            (click)="nextStepTutorial()"
          >
            {{
              isTestRoom
                ? getText('notCameraButton')
                : ('continueWithoutCamera' | translate)
            }}
          </button>
        </div>
      </ng-template>
    </div>
  </div>

  <p class="txt-narrator" *ngIf="tutorialStep === 0 && !isTestRoom">
    <em>{{ 'devicesOkGoToSkip' | translate }}</em>
  </p>
</div>

<div class="cnt-no-support">
  <p *ngIf="!warn">
    {{ 'notSupportedBrowserMsg' | translate }}
  </p>
  <p *ngIf="warn">
    {{ 'notFullySupportedMsg' | translate }}
  </p>
  <div class="browsers">
    <div class="chrome">
      <img src="assets/images/chrome.svg" alt="chrome" />
      <span>Chrome</span>
      <br />
      <span class="version"
        >{{ minChrome }} <br />
        - <br />
        {{ maxChrome }}</span
      >
    </div>

    <div class="firefox">
      <img src="assets/images/firefox.svg" alt="firefox" />
      <span>Firefox</span>
      <br />
      <span class="version"
        >{{ minFirefox }} <br />
        - <br />
        {{ maxFirefox }}</span
      >
    </div>

    <div class="edge">
      <img src="assets/images/edge_chrome.jpg" alt="edge" />
      <span>Edge</span>
      <br />
      <span class="version"
        >{{ minEdge }} <br />
        - <br />
        {{ maxEdge }}</span
      >
    </div>

    <div class="safari">
      <img src="assets/images/safari.png" alt="safari" />
      <span>Safari</span>
      <br />
      <span class="version"
        >{{ minSafari }} <br />
        - <br />
        {{ maxSafari }}</span
      >
    </div>

    <div class="opera">
      <img src="assets/images/opera.jpg" alt="opera" />
      <span>Opera</span>
      <br />
      <span class="version"
        >{{ minOpera }} <br />
        - <br />
        {{ maxOpera }}</span
      >
    </div>
  </div>

  <div *ngIf="!warn" class="banner-contact">
    <div class="alert alert-warning border-style">
      <span
        class="contact-phones"
        *ngIf="(_getContactPhones() | async).length > 0"
      >
        {{ 'audioWarningMsg1' | translate
        }}<b class="phoneNumber">{{ _getContactPhones() | async }}</b
        >{{ 'audioWarningMsg2' | translate }}
      </span>
    </div>
  </div>
</div>

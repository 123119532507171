import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorHandlerService: ErrorHandlerService,
    private _router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof
          HttpErrorResponse /*&&
          !this._router.url.includes('login')*/
        ) {
          const errorClone = JSON.parse(JSON.stringify(error));
          if (!errorClone.error.status) {
            errorClone.error = {
              status: errorClone.status,
            };
          }
          errorClone.error.status =
            error.status === 404 || error.status === 500 ? 600 : error.status;
          this.errorHandlerService.manageApiError(errorClone.error);
        }

        return throwError(error);
      })
    );
  }
}

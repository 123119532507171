import { Component, Input, OnInit } from '@angular/core';
import { selectTelephones } from '../../store/configApp/config.selector';
import { Store } from '@ngrx/store';
import { Constant } from '../../utils/constant';

@Component({
  selector: 'app-browser-desktop-unsupported',
  templateUrl: './browser-desktop-unsupported.component.html',
  styleUrls: ['./browser-desktop-unsupported.component.scss'],
})
export class BrowserDesktopUnsupportedComponent implements OnInit {
  @Input() idRoom: string;
  @Input() warn: boolean;
  minChrome = Constant.VERSIONS.CHROME.MIN;
  maxChrome = Constant.VERSIONS.CHROME.MAX;
  minFirefox = Constant.VERSIONS.FIREFOX.MIN;
  maxFirefox = Constant.VERSIONS.FIREFOX.MAX;
  minEdge = Constant.VERSIONS.EDGE.MIN;
  maxEdge = Constant.VERSIONS.EDGE.MAX;
  minSafari = Constant.VERSIONS.SAFARI.MIN;
  maxSafari = Constant.VERSIONS.SAFARI.MAX;
  minOpera = Constant.VERSIONS.OPERA.MIN;
  maxOpera = Constant.VERSIONS.OPERA.MAX;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this._getContactPhones();
  }

  public _getContactPhones() {
    return this._store.select(selectTelephones);
  }
}

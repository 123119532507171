export class ScreenInformationModel {
  public place: string;
  public ticket: string;
  public time: Date;

  constructor() {
    this.place = '';
    this.ticket = '';
    this.time = new Date();
  }
}

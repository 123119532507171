import { Action, createReducer, on } from '@ngrx/store';
import { initStateAppointment } from './appointment.state';
import { appointmentAction } from './appointment.action';
import { AppointmentDateInterface } from '../../interfaces/appointment-date.interface';

export const appointmentReducer = createReducer(
  initStateAppointment,
  on(appointmentAction.setAppointmentData, (state, { appointment }) => ({
    ...state,
    ...appointment,
  }))
);

export function appointmentReducers(
  state: AppointmentDateInterface | undefined,
  action: Action
) {
  return appointmentReducer(state, action);
}

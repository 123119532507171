import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-waiting-room-message',
  templateUrl: './waiting-room-message.component.html',
  styleUrls: ['./waiting-room-message.component.scss'],
})
export class WaitingRoomMessageComponent implements OnInit {
  @Input() public message: string[];
  @Input() public showButton: boolean;
  @Input() public error: boolean;
  @Input() acceptButton: string;
  @Input() idRoom: string;
  @Input() public translationParams: any;
  @Input() style: boolean;
  @Output() accept: EventEmitter<boolean> = new EventEmitter();

  public lang: string;

  constructor(private _router: Router) {}

  ngOnInit() {}

  public viewTerms() {
    this.lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'es';

    const url = this._router.createUrlTree([
      '/assistant/conditions',
      this.idRoom,
      this.lang,
    ]);
    window.open(url.toString(), '_blank');
  }
}

import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GetConfigurationService } from '../../services/get-configuration/get-configuration.service';
import { centerAction } from './center.action';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Injectable()
export class CenterEffect {
  constructor(
    private actions$: Actions,
    private _configurationService: GetConfigurationService,
    private _spinnerService: SpinnerService
  ) {}

  fetchCenterResponse = createEffect(() =>
    this.actions$.pipe(
      ofType(centerAction.fetchCenterResponse),
      switchMap(() => {
        this._spinnerService.show();
        return this._configurationService.getDataCenterResponse().pipe(
          map((res) => {
            this._spinnerService.hide();
            return centerAction.setCenterResponse({ centerResponse: res });
          }),
          catchError((err) => {
            this._spinnerService.hide();
            return throwError(err);
          })
        );
      })
    )
  );
}

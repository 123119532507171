import { Component, Input, OnInit } from '@angular/core';
import { selectTelephones } from '../../store/configApp/config.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-contact-phones',
  templateUrl: './contact-phones.component.html',
  styleUrls: ['./contact-phones.component.scss'],
})
export class ContactPhonesComponent implements OnInit {
  @Input() inCall: boolean;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this._getContactPhones();
  }

  public _getContactPhones() {
    return this._store.select(selectTelephones);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DataParamsInterface } from '../../store/interface/data-params.interface';
import { Store } from '@ngrx/store';
import { randomParticipantAction } from '../../store/random-participant/random-participant.action';
import { Constant } from '../../utils/constant';

@Component({
  selector: 'app-select-participant',
  templateUrl: './select-participant.component.html',
  styleUrls: ['./select-participant.component.scss'],
})
export class SelectParticipantComponent implements OnInit {
  private _participants: DataParamsInterface[];
  @Input() set participants(value: DataParamsInterface[]) {
    this._participants = value;
  }

  constructor(private _store: Store) {
    this._participants = [];
  }

  ngOnInit(): void {}

  /**
   * @return DataParamsInterface[]
   * Listener for modifications to the _participant object.
   * It is launched every time it is modified
   */
  get getAllParticipants(): DataParamsInterface[] {
    return this._participants;
  }

  /**
   *
   * @param participant DataParamsInterface
   * update the status of a single participant and shoot it to the store,
   * it will be collected in the video-room
   */
  public updateState(participant: DataParamsInterface) {
    if (participant.state === Constant.ADMIN_STATES.OPEN) {
      participant = {
        ...participant,
        state: Constant.ADMIN_STATES.STAND_BY,
      };
    } else if (
      participant.state === Constant.ADMIN_STATES.STAND_BY ||
      participant.state === Constant.ADMIN_STATES.READY
    ) {
      participant = {
        ...participant,
        state: Constant.ADMIN_STATES.OPEN,
      };
    }
    this._store.dispatch(
      randomParticipantAction.setRandomParticipants({
        randomParticipants: participant,
      })
    );
  }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectUrlLogo } from '../../store/centerResponse/center.selector';
import { Subject } from 'rxjs';
import { Constant } from '../../utils/constant';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit, OnDestroy {
  public urlLogo: string;
  private _unsubscriber$: Subject<void> = new Subject();
  @Input() classImport: string;
  @Input() logoTest: string;
  @Input() isTest: boolean;

  constructor(private _store: Store) {}

  ngOnInit(): void {
    if (this.isTest) {
      this._getLogoTest();
    } else {
      this._getLogo();
    }
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }

  private _getLogoTest(): void {
    if (this.logoTest) {
      this.urlLogo = `${Constant.PREFIX_IMG_64}${this.logoTest}`;
    } else {
      this.urlLogo = Constant.PATH_LOGO_DEFAULT;
    }
  }

  private _getLogo(): void {
    this._store
      .select(selectUrlLogo)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((urlLogo) => {
        if (urlLogo) {
          this.urlLogo = `${Constant.PREFIX_IMG_64}${urlLogo}`;
        } else {
          this.urlLogo = Constant.PATH_LOGO_DEFAULT;
        }
      });
  }
}

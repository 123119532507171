export class DataParamsModel {
  public id: number;
  public groupmeeting: string;
  public location: string;
  public data: string;
  public checkin: boolean;
  public client: string;
  public admin: string;
  public apiurl: string;
  public inGroup: boolean;
  public created: Date;
  public modified: Date;
  public room: string;
  public roommgt: string;
  public state: any;
  public token: string;
  public tokenmgt: string;
  public profile: string;
  public roomType: string;
  public meetingUrl: string;

  constructor() {
    this.id = 0;
    this.groupmeeting = '';
    this.location = '';
    this.data = '';
    this.checkin = false;
    this.client = '';
    this.admin = '';
    this.apiurl = '';
    this.created = new Date();
    this.modified = new Date();
    this.room = '';
    this.inGroup = false;
    this.roommgt = '';
    this.state = null;
    this.token = '';
    this.tokenmgt = '';
    this.profile = '';
    this.roomType = '';
    this.meetingUrl = '';
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-permissions',
  templateUrl: './alert-permissions.component.html',
  styleUrls: ['./alert-permissions.component.scss'],
})
export class AlertPermissionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

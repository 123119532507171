export class DetailOfRoomModel {
  callDuration: number;
  callDurationUnit: string;
  fromDate: string;
  toDate: string;
  maxParticipants: number;
  currentParticipants: number;
  room: string;
  id: number;
  userProblem: boolean;
  userReady: boolean;
  printScreenRoles: string[];
  inviteRoles: string[];
  shareFileRoles: string[];

  constructor() {
    this.callDuration = 0;
    this.callDurationUnit = '';
    this.fromDate = '';
    this.toDate = '';
    this.maxParticipants = 0;
    this.currentParticipants = 0;
    this.room = '';
    this.id = 0;
    this.userProblem = false;
    this.userReady = false;
    this.printScreenRoles = [];
    this.inviteRoles = [];
    this.shareFileRoles = [];
  }
}

import { Injectable, RendererFactory2 } from '@angular/core';
import { Spinner } from '../extended-spinner/spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService extends Spinner {
  constructor(renderFactory: RendererFactory2) {
    super(renderFactory, 'cnt-spinner', 'spinner');
  }
}

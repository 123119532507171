import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { selectLanguage } from '../../store/centerResponse/center.selector';
import { Subject } from 'rxjs';
import { textAction } from '../../store/text/text.action';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  public languages;
  public selected;
  public showListLanguage: boolean;
  private _unsubscriber$: Subject<void> = new Subject();
  private _langs: string[];
  @Input() group: string;
  @Input() location: string;
  @Input() set langs(value: string[]) {
    this._langs = value;
    this.setLanguageTest();
  }
  @Input() isTest: boolean;

  constructor(private _store: Store, private _translate: TranslateService) {}

  ngOnInit() {
    this.languages = [];
    if (this.isTest) {
      this.setLanguageTest();
    } else {
      this._getLanguage();
    }
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }

  private _getLanguage(): void {
    this._store
      .select(selectLanguage)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((langs) => {
        if (langs && langs.length > 0) {
          this.languages = langs;
          this.selected = localStorage.getItem('lang') ?? langs[0];
          localStorage.setItem('lang', this.selected.toLowerCase());
        } else {
          this.selected = 'es';
        }
        if (this.selected) {
          this._translate.setDefaultLang(this.selected);
        }
      });
  }

  private setLanguageTest(): void {
    if (this._langs && this._langs.length > 0) {
      this.languages = this._langs;
      this.selected = this._langs[0].toLowerCase();
      localStorage.setItem('lang', this.languages[0].toLowerCase());
    } else {
      this.selected = 'es';
    }
    if (this.selected) {
      this._translate.setDefaultLang(this.selected);
    }
  }

  public changeLanguage(lang) {
    this.selected = lang;
    localStorage.setItem('lang', lang.toLowerCase());
    this._translate.setDefaultLang(lang.toLowerCase());
    if (this.isTest && this.group && this.location) {
      this._store.dispatch(
        textAction.fetchAllTexts({
          lang: this.selected,
          location: this.location,
          group: this.group,
        })
      );
    }
    this.showListLanguage = false;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  selectAppointmentDate,
  selectAppointmentLastAppDatCall,
  selectAppointmentName,
} from '../../store/appointment/appointment.selector';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { selectDetail } from '../../store/configApp/config.selector';

@Component({
  selector: 'app-info-waiting',
  templateUrl: './info-waiting.component.html',
  styleUrls: ['./info-waiting.component.scss'],
})
export class InfoWaitingComponent implements OnInit, OnDestroy {
  public appointmentDate: Date;
  public lastCall: Date;
  private _unsubscriber$: Subject<void> = new Subject();

  constructor(private _store: Store) {}

  ngOnInit(): void {
    this._getAppointmentDate();
    this._lastCall();
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }

  public getNamePerson$(): Observable<any> {
    return this._store.select(selectAppointmentName);
  }

  private _getAppointmentDate(): void {
    this._store
      .select(selectAppointmentDate)
      .pipe(
        filter((res) => res !== ''),
        takeUntil(this._unsubscriber$)
      )
      .subscribe((appointment) => {
        if (appointment) {
          this.appointmentDate = new Date(appointment);
        } else {
          this._store
            .select(selectDetail)
            .pipe(takeUntil(this._unsubscriber$))
            .subscribe((res) => {
              this.appointmentDate = new Date(res.fromDate);
            });
        }
      });
  }

  private _lastCall(): void {
    this._store
      .select(selectAppointmentLastAppDatCall)
      .pipe(
        filter((res) => res !== ''),
        takeUntil(this._unsubscriber$)
      )
      .subscribe((lastCall) => {
        if (lastCall) {
          this.lastCall = new Date(lastCall);
        }
      });
  }
}

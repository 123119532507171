import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { Store } from '@ngrx/store';
import { rootAction } from '../../store/root.action';

@Component({
  selector: 'app-self-video',
  templateUrl: './self-video.component.html',
  styleUrls: ['./self-video.component.scss'],
})
export class SelfVideoComponent implements OnInit {
  public srcVideo: any;

  @ViewChild('videoSelf') videoSelf: ElementRef;

  constructor(private _modalService: ModalService, private _store: Store) {}

  ngOnInit(): void {
    this._getStreamVideoCam();
  }

  public _getStreamVideoCam(): void {
    navigator.mediaDevices
      .getUserMedia({
        video: { width: 300, height: 200 },
        audio: true
      })
      .then((stream) => {
        this.videoSelf.nativeElement.srcObject = stream;
        this._store.dispatch(
          rootAction.setPermisionCamera({
            permission: {
              permission: true,
              deny: false,
            },
          })
        );
        this._store.dispatch(
          rootAction.setPermisionMicrophone({
            permission: {
              permission: true,
              deny: false,
            },
          })
        );
        this._modalService.hide();
      })
      .catch((err) => {
        this._modalService.hide();
        this._store.dispatch(
          rootAction.setPermisionCamera({
            permission: {
              permission: false,
              deny: true,
            },
          })
        );
      });
  }
}

<div class="overView" [ngClass]="{'back-view': backView, 'back-view-opacity': backViewOpacity}"></div>
<div [ngClass]="{'modal-cnt': !removeDefaultClass}">
  <i class="material-icons close-modal-icon" *ngIf="closeButton" (click)="closeModal()">close</i>
  <h3>{{headTitle | translate}}</h3>
  <hr />
  <ng-template #component></ng-template>
  <div class="footer">
    <button class="btn-blue" *ngIf="closeButtonBottom" (click)="closeModal()">{{textButtonClosed | translate}}</button>
  </div>
</div>

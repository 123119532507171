import { createAction, props } from '@ngrx/store';
import { DataParamsInterface } from '../interface/data-params.interface';

const setRandomParticipants = createAction(
  '[jitsi] recive a Participant Data',
  props<{ randomParticipants: DataParamsInterface }>()
);

export const randomParticipantAction = {
  setRandomParticipants,
};

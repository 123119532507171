import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { JistiApiService } from '../service/jisti-api/jisti-api.service';

@Component({
  selector: 'app-videocam',
  templateUrl: './videocam.component.html',
  styleUrls: ['./videocam.component.scss'],
})
export class VideocamComponent implements OnInit, OnDestroy {
  private _roomName: string;
  private _token: string;
  private _subject: string;
  @Input() public domain: string;
  private _initJitsiFlag: boolean;
  @Input() set idRoom(value: string) {
    this._roomName = value;
    this._initJitsi();
  }
  @Input() public width: number;
  @Input() public height: number;
  @Input() public divClass: string;
  @Input() set token(value: string) {
    if (value) {
      this._token = value;
      this._initJitsi();
    }
  }
  @Input() public name: string;
  @Input() public isFirefox: boolean;
  @Input() public isSafari: boolean;
  @Input() groupMeeting: string;
  @Input() set subject(value: string) {
    if (value) {
      this._subject = value;
      this._initJitsi();
    }
  }

  @Output() hangUp: EventEmitter<boolean> = new EventEmitter();
  @Output() participantLeft: EventEmitter<boolean> = new EventEmitter();
  @Output() participantJoined: EventEmitter<boolean> = new EventEmitter();

  private isAdmin: boolean;

  showLoading = true;
  div;

  constructor(
    public _jistiService: JistiApiService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.isAdmin = this._checkIsAdmin();
    this.div = this.renderer.createElement('div');
    this.renderer.addClass(this.div, this.divClass);
    this.renderer.addClass(this.div, 'cnt-video');
    if (this.isAdmin) {
      this.renderer.addClass(this.div, 'admin');
    }
    this.renderer.appendChild(this.el.nativeElement, this.div);

    this._initJitsi();
    this.showLoading = false;
  }

  private _checkIsAdmin(): boolean {
    return window.location.href.indexOf('admin') !== -1;
  }

  private _initJitsi(): void {
    this._initJitsiFlag = true;
    this._jistiService?.dispose();
    this._jistiService.initJitsiComponent(
      this.domain,
      this._roomName,
      this.width,
      this.height,
      // this.verifyPermission,
      this.hangUp,
      this.participantLeft,
      this.participantJoined,
      this.div,
      this._token,
      this.isFirefox,
      this.isSafari,
      this.groupMeeting,
      this.name,
      this._subject
    );
  }

  ngOnDestroy(): void {
    this.renderer.destroy();
  }
}

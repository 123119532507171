import { Action, createReducer, on } from '@ngrx/store';
import {
  initRandomParticipant,
  RandomParticipantState,
} from './random-participant.state';
import { randomParticipantAction } from './random-participant.action';

export const randomParticipantReducer = createReducer(
  initRandomParticipant,
  on(
    randomParticipantAction.setRandomParticipants,
    (state, { randomParticipants }) => ({
      ...state,
      randomParticipant: randomParticipants,
    })
  )
);

export function randomParticipantReducers(
  state: RandomParticipantState | undefined,
  action: Action
) {
  return randomParticipantReducer(state, action);
}

import { createAction, props } from '@ngrx/store';
import { AppointmentDateInterface } from '../../interfaces/appointment-date.interface';

const fetchAppointmentData = createAction(
  '[jisti] fetchAppointmentData',
  props<{ room: string }>()
);

const setAppointmentData = createAction(
  '[jisti] setAppointmentData',
  props<{ appointment: AppointmentDateInterface }>()
);

export const appointmentAction = {
  fetchAppointmentData,
  setAppointmentData,
};

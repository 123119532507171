import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { ModalComponent } from './modal/modal.component';
import { ParamsModal } from './interface/params-modal';
import { Subject, Subscription } from 'rxjs';
import { rootAction } from '../../store/root.action';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _modal: ComponentFactory<ModalComponent>;
  private _modalRef: ComponentRef<ModalComponent>;
  private _unsuscribe: Subject<Subscription> = new Subject<Subscription>();

  constructor(
    private _factoryResolver: ComponentFactoryResolver,
    private _store: Store
  ) {}

  public show(
    classComponent: any,
    params: ParamsModal[],
    titleModal: string,
    viewContainerRef: ViewContainerRef,
    closed: boolean,
    buttonClosed: boolean = false,
    textButtonClosed: string = '',
    backView: boolean = false,
    removeDefaultClass: boolean = false,
    backViewOpacity: boolean = false
  ) {
    if (!this._modalRef) {
      this._modal = this._factoryResolver.resolveComponentFactory(
        ModalComponent
      );
      this._modalRef = viewContainerRef.createComponent(this._modal);
      this._modalRef.instance.paramsComponent = params;
      this._modalRef.instance.headTitle = titleModal;
      this._modalRef.instance.closeButton = closed;
      this._modalRef.instance.closeButtonBottom = buttonClosed;
      this._modalRef.instance.textButtonClosed = textButtonClosed;
      this._modalRef.instance.backView = backView;
      this._modalRef.instance.removeDefaultClass = removeDefaultClass;
      this._modalRef.instance.backViewOpacity = backViewOpacity;
      this._modalRef.instance.body = classComponent;
      this._modalRef.instance.closed.subscribe((close) => {
        if (close) {
          this._closeModal();
        }
      });
    }
  }

  public hide() {
    this._closeModal();
  }

  private _closeModal() {
    if (this._modalRef) {
      this._modalRef.destroy();
      this._modalRef = null;
      this._unsuscribe.unsubscribe();
      this._store.dispatch(rootAction.setModalStatus({ modalStatus: false }));
    }
  }
}

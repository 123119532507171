import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { Constant } from './utils/constant';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { IsGroupalService } from './services/is-groupal/is-groupal.service';
import { Store } from '@ngrx/store';
import { selectRoomMgt } from './store/root.selector';
import { IsGroupalInterface } from './interfaces/is-groupal.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _isGroupalService: IsGroupalService,
    private _store: Store,
    private _activatedRoute: ActivatedRoute
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._store.select(selectRoomMgt).pipe(
      map((roomMap) => {
        if (roomMap === '') {
          roomMap = null;
        }
        return roomMap;
      }),
      switchMap((room: string) => {
        console.log(
          location.pathname.substr(
            location.pathname.lastIndexOf('/') + 1,
            location.pathname.length
          )
        );
        return this._isGroupalService
          .getIsGroupal$(
            room ??
              location.pathname.substr(
                location.pathname.lastIndexOf('/') + 1,
                location.pathname.length
              )
          )
          .pipe(
            filter(
              (res: IsGroupalInterface) => res !== null || res?.profile !== ''
            ),
            switchMap((params) => {
              if (params.profile === Constant.ADMIN) {
                return of(true);
              } else {
                this._router.navigateByUrl('/views/error', {
                  state: { error: '403' },
                });
                return of(false);
              }
            }),
            catchError(() => of(false))
          );
      })
    );
  }
}

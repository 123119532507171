import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TextsInterface } from '../../interfaces/texts-interface';
import { Observable, of } from 'rxjs';
import { urls } from '../../utils/urls';
import { HeadersService } from '../headers/headers.service';

@Injectable({
  providedIn: 'root',
})
export class TextService {
  constructor(
    private readonly _http: HttpClient,
    private _headersService: HeadersService
  ) {}

  public getText(group, location, lang, key): Observable<string> {
    const headers = this._headersService.getHeaders();
    const params = new HttpParams()
      .set('group', group)
      .set('location', location)
      .set('language', lang)
      .set('txtKey', key);
    return of(null);
  }

  public getAllText(group, location, lang): Observable<TextsInterface[]> {
    const headers = this._headersService.getHeaders();
    const params = new HttpParams()
      .set('group', group)
      .set('location', location)
      .set('language', lang);
    return this._http.get<TextsInterface[]>(
      '/api' + urls.final.getAllTexts,
      { headers, params }
    );
  }

  public filterText(key: string, texts: TextsInterface[]): string {
    return texts.filter((item) => item.txtKey === key)[0].content;
  }
}

import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetBrowserService } from './services/get-browser/get-browser.service';
import { Store } from '@ngrx/store';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  selectBackground,
  selectLanguage,
} from './store/centerResponse/center.selector';
import { centerAction } from './store/centerResponse/center.action';
import {
  selectBrowser,
  selectDataParams,
  selectGroupRooms,
  selectIdRoom,
  selectSo,
} from './store/root.selector';
import { Observable, Subject, timer } from 'rxjs';
import { rootAction } from './store/root.action';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Constant } from './utils/constant';
import { AppConfigService } from './services/app-config/app-config.service';
import { ModalService } from './services/modal/modal.service';
import { BrowserDesktopUnsupportedComponent } from './components/browser-desktop-unsupported/browser-desktop-unsupported.component';
import { configAppActions } from './store/configApp/config.action';
import { DetailOfRoomInterface } from './interfaces/detail-of-room.interface';
import { GetConfigurationService } from './services/get-configuration/get-configuration.service';
import { selectGroupal } from './store/goupal/groupal.selector';
import { isGroupalActions } from './store/goupal/groupal.action';
import { DataParamsInterface } from './store/interface/data-params.interface';
import { SplashService } from './services/splash/splash.service';
import { StatisticsService } from './services/statistics/statistics.service';
import { VersionCompare } from 'version_compare';
import { SpinnerBlackService } from './services/spinner-black/spinner-black.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [TranslateService],
})
export class AppComponent implements OnDestroy, OnInit {
  private _unsubscriber$: Subject<void> = new Subject();
  private _unsubscriberDataParams$: Subject<void> = new Subject();
  title = 'plexus-jitsi';
  browser: string;
  public idRoom: string;
  private _isGroupal: boolean;
  private _isAdmin: boolean;
  private isAppRoute: boolean;
  public detailOfRoom: DetailOfRoomInterface;
  public viewContainerRef: ViewContainerRef;

  constructor(
    private _translate: TranslateService,
    private _browserService: GetBrowserService,
    private _store: Store,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _appConfig: AppConfigService,
    private _view: ViewContainerRef,
    private _modal: ModalService,
    private dataParamsService: GetConfigurationService,
    private _splashService: SplashService,
    private _spinnerService: SpinnerBlackService,
    private _statisticsService: StatisticsService
  ) {
    this.viewContainerRef = this._view;
    this._appConfig.loadAppConfig().then((data) => {});
    this.isAppRoute = false;
    this._browserService.getBrowser();
    this._getIdRoom();
    this._isGroup();
    this._activatedRoute.paramMap
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((params: ParamMap) => {
        const idRoom = params.get(Constant.ID_ROOM);
        if (idRoom) {
          this.isAppRoute = true;
        }
        this._store.dispatch(rootAction.setIdRoom({ idRoom }));
      });
  }

  ngOnInit(): void {
    this._getLanguage();
    this._getBackground();
    timer().subscribe(() => {
      this._spinnerService.show();
    });
  }

  private _getConfigApp() {
    this._store.dispatch(configAppActions.fetchConfigApp());
  }

  private _isGroup(): void {
    this._store
      .select(selectGroupal)
      .pipe(
        filter((res) => res.profile !== ''),
        takeUntil(this._unsubscriber$)
      )
      .subscribe((res) => {
        if (res.inGroup && res.profile === Constant.ADMIN) {
          this._isGroupal = true;
          this._isAdmin = true;
          this._store.dispatch(
            rootAction.fetchGroupRooms({ room: this.idRoom })
          );
          this._getGroupRooms();
        } else {
          this._fetchDataParams(this.idRoom);
        }
      });
  }

  private _getIdRoom() {
    this._store
      .select(selectIdRoom)
      .pipe(
        filter((idRoom: string) => idRoom !== null && idRoom !== ''),
        takeUntil(this._unsubscriber$)
      )
      .subscribe((idRoom: string) => {
        this.idRoom = idRoom;
        if (idRoom) {
          this._store.dispatch(
            isGroupalActions.fetchIsGroupal({ room: idRoom })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }

  private _getGroupRooms(): void {
    this._store
      .select(selectGroupRooms)
      .pipe(
        filter((res) => res[0].profile !== ''),
        takeUntil(this._unsubscriber$),
        map((res) => res[0]),
        map((res) => ({ ...res, profile: Constant.ADMIN }))
      )
      .subscribe((res: DataParamsInterface) => {
        this._store.dispatch(rootAction.setDataParams({ dataParams: res }));
        this._getDataParams();
      });
  }

  private _getBackground(): void {
    this._store
      .select(selectBackground)
      .pipe(
        filter((res) => res !== ''),
        takeUntil(this._unsubscriber$)
      )
      .subscribe((background) => {
        document.body.style.background = background;
        timer(1000).subscribe(() => this._spinnerService.hide());
      });
  }

  private _loadConfiguration(): void {
    this._store.dispatch(centerAction.fetchCenterResponse());
  }

  private _getDataParams(): void {
    this._store
      .select(selectDataParams)
      .pipe(
        filter((res) => res.id !== 0 && !!res.id),
        takeUntil(this._unsubscriberDataParams$)
      )
      .subscribe((res) => {
        this._checkBrowserInfo();
        this._getConfigApp();
        this._loadConfiguration();
        this._checkOptionalFunctions(res.room);
        this._unsubscriberDataParams$.next();
        this._unsubscriberDataParams$.unsubscribe();
        if (this.isAppRoute) {
          if (res.profile === Constant.ADMIN) {
            if (res.roomType === Constant.TEAMS) {
              this._updateState(Constant.ADMIN_STATES.OPEN).subscribe(
                (res1) => {
                  location.href = res1.meetingUrl;
                }
              );
            } else {
              this._router.navigate(['admin', 'video', this.idRoom]);
            }
          } else {
            this._router.navigate([
              'assistant',
              'waiting',
              this.idRoom,
              'welcome',
            ]);
          }
        }
      });
  }

  private _getLanguage(): void {
    if (!localStorage.getItem('lang')) {
      this._store
      .select(selectLanguage)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((langs: string[]) => {
        if (langs.length === 0) {
          this._translate.setDefaultLang('es');
          localStorage.setItem('lang', 'es');
        } else {
          localStorage.setItem('lang', langs[0].toLowerCase());
        }
      });
    }
  }

  private _fetchDataParams(idRoom: string): void {
    if (idRoom) {
      this._store.dispatch(
        rootAction.fetchDataParams({
          params: escape(idRoom),
        })
      );
    }

    this._getDataParams();
  }

  private _checkBrowserInfo(): void {
    this._store.select(selectBrowser).subscribe((browser) => {
      let browserName: string;
      if (browser === Constant.BROWSER.UNK) {
        browserName = 'Other';
      } else if (browser === Constant.BROWSER.OLD_ED) {
        browserName = Constant.BROWSER.ED;
      } else {
        browserName = browser;
      }
      const version = this._getBrowserVersion(browserName);
      this._statisticsService.sendBrowserInfo(browserName, version).subscribe();

      this._store.select(selectSo).subscribe((so) => {
        if (so === Constant.SO.DESKTOP && this._router.url.indexOf('conditions') === -1) {
          if (
            !this._checkMinVersionBrowsers(browser, version) ||
            !this._browserService.checkBrowserWebRTC()
          ) {
            this._showModalUnsupportedBrowser();
          } else {
            if (!this._checkMaxVersionBrowsers(browser, version)) {
              this._showWarningUnsupportedBrowser();
            }
          }
        }
      });
    });
  }

  private _checkOptionalFunctions(roomClient: string): void {
    this.dataParamsService
      .getConfigurationDetailRoom(roomClient)
      .pipe(
        takeUntil(this._unsubscriber$),
        filter((res: DetailOfRoomInterface) => res !== null)
      )
      .subscribe((res) => {
        this.detailOfRoom = res;

        const shareFileRolesAux = this.detailOfRoom.shareFileRoles;
        this.detailOfRoom.shareFileRoles = [];
        shareFileRolesAux.forEach((rol) => {
          this.detailOfRoom.shareFileRoles.push(rol.toLowerCase());
        });

        const inviteRolesAux = this.detailOfRoom.inviteRoles;
        this.detailOfRoom.inviteRoles = [];
        inviteRolesAux.forEach((rol) => {
          this.detailOfRoom.inviteRoles.push(rol.toLowerCase());
        });

        const printScreenRolesAux = this.detailOfRoom.printScreenRoles;
        this.detailOfRoom.printScreenRoles = [];
        printScreenRolesAux.forEach((rol) => {
          this.detailOfRoom.printScreenRoles.push(rol.toLowerCase());
        });

        this._store.dispatch(
          rootAction.setDetailOfRoom({ detailOfRoom: this.detailOfRoom })
        );
      });
  }

  private _updateState(state: string): Observable<DataParamsInterface> {
    return this.dataParamsService
      .patchDataParams(escape(this.idRoom), state)
      .pipe(takeUntil(this._unsubscriber$));
  }

  private _showModalUnsupportedBrowser(): void {
    this._modal.show(
      BrowserDesktopUnsupportedComponent,
      [
        {
          key: 'idRoom',
          value: this.idRoom,
        },
      ],
      Constant.NOT_SUPPORTED_BROWSER,
      this._view,
      false,
      false,
      '',
      true
    );
  }

  private _showWarningUnsupportedBrowser(): void {
    this._modal.show(
      BrowserDesktopUnsupportedComponent,
      [
        {
          key: 'idRoom',
          value: this.idRoom,
        },
        {
          key: 'warn',
          value: true,
        },
      ],
      Constant.NOT_FULLY_SUPPORTED,
      this._view,
      true,
      true,
      'acceptButton',
      true
    );
  }

  private _getBrowserVersion(browserName: string): string {
    let version: string;
    switch (browserName) {
      case Constant.BROWSER.CH:
        version = navigator.userAgent.substring(
          navigator.userAgent.indexOf('Chrome/') + 7,
          navigator.userAgent.indexOf(
            ' ',
            navigator.userAgent.indexOf('Chrome/')
          )
        );
        break;

      case Constant.BROWSER.iOSCH:
        version = navigator.userAgent.substring(
          navigator.userAgent.indexOf('CriOS/') + 6,
          navigator.userAgent.indexOf(
            ' ',
            navigator.userAgent.indexOf('CriOS/')
          )
        );
        break;

      case Constant.BROWSER.IE:
        version = navigator.userAgent.substring(
          navigator.userAgent.indexOf('MSIE') + 5,
          navigator.userAgent.indexOf(';', navigator.userAgent.indexOf('MSIE'))
        );
        break;

      case Constant.BROWSER.SA:
        version = navigator.userAgent.substring(
          navigator.userAgent.indexOf('Version/') + 8,
          navigator.userAgent.indexOf(
            ' ',
            navigator.userAgent.indexOf('Version/')
          )
        );
        break;

      case Constant.BROWSER.ED:
      case Constant.BROWSER.FF:
      case Constant.BROWSER.OP:
        version = navigator.userAgent.substring(
          navigator.userAgent.lastIndexOf('/') + 1
        );
        break;

      default:
        version = Constant.BROWSER.UNK;
        break;
    }

    return version;
  }

  private _checkMinVersionBrowsers(browser: string, version: string) {
    let minVersionValid = false;

    // @ts-ignore
    const versionCompare = require('version_compare');

    switch (browser) {
      case Constant.BROWSER.CH:
        if (
          versionCompare.gte(
            version,
            Constant.VERSIONS.CHROME.MIN
          ) /*&&
          (!Constant.VERSIONS.CHROME.MAX ||
            versionCompare.lte(version, Constant.VERSIONS.CHROME.MAX))*/
        ) {
          minVersionValid = true;
        }
        break;
      case Constant.BROWSER.FF:
        if (
          versionCompare.gte(
            version,
            Constant.VERSIONS.FIREFOX.MIN
          ) /*&&
          (!Constant.VERSIONS.FIREFOX.MAX ||
            versionCompare.lte(version, Constant.VERSIONS.FIREFOX.MAX))*/
        ) {
          minVersionValid = true;
        }
        break;
      case Constant.BROWSER.ED:
        if (
          versionCompare.gte(
            version,
            Constant.VERSIONS.EDGE.MIN
          ) /*&&
          (!Constant.VERSIONS.EDGE.MAX ||
            versionCompare.lte(version, Constant.VERSIONS.EDGE.MAX))*/
        ) {
          minVersionValid = true;
        }
        break;
      case Constant.BROWSER.SA:
        if (
          versionCompare.gte(
            version,
            Constant.VERSIONS.SAFARI.MIN
          ) /*&&
          (!Constant.VERSIONS.SAFARI.MAX ||
            versionCompare.lte(version, Constant.VERSIONS.SAFARI.MAX))*/
        ) {
          minVersionValid = true;
        }
        break;
      case Constant.BROWSER.OP:
        if (
          versionCompare.gte(
            version,
            Constant.VERSIONS.OPERA.MIN
          ) /*&&
          (!Constant.VERSIONS.OPERA.MAX ||
            versionCompare.lte(version, Constant.VERSIONS.OPERA.MAX))*/
        ) {
          minVersionValid = true;
        }
        break;
      default:
        minVersionValid = false;
        break;
    }

    return minVersionValid;
  }

  private _checkMaxVersionBrowsers(browser: string, version: string) {
    let maxVersionValid = false;

    // @ts-ignore
    const versionCompare = require('version_compare');

    switch (browser) {
      case Constant.BROWSER.CH:
        if (
          !Constant.VERSIONS.CHROME.MAX ||
          versionCompare.lte(version, Constant.VERSIONS.CHROME.MAX)
        ) {
          maxVersionValid = true;
        }
        break;
      case Constant.BROWSER.FF:
        if (
          !Constant.VERSIONS.FIREFOX.MAX ||
          versionCompare.lte(version, Constant.VERSIONS.FIREFOX.MAX)
        ) {
          maxVersionValid = true;
        }
        break;
      case Constant.BROWSER.ED:
        if (
          !Constant.VERSIONS.EDGE.MAX ||
          versionCompare.lte(version, Constant.VERSIONS.EDGE.MAX)
        ) {
          maxVersionValid = true;
        }
        break;
      case Constant.BROWSER.SA:
        if (
          !Constant.VERSIONS.SAFARI.MAX ||
          versionCompare.lte(version, Constant.VERSIONS.SAFARI.MAX)
        ) {
          maxVersionValid = true;
        }
        break;
      case Constant.BROWSER.OP:
        if (
          !Constant.VERSIONS.OPERA.MAX ||
          versionCompare.lte(version, Constant.VERSIONS.OPERA.MAX)
        ) {
          maxVersionValid = true;
        }
        break;
      default:
        maxVersionValid = false;
        break;
    }

    return maxVersionValid;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { selectBrowser } from 'src/app/store/root.selector';
import { Constant } from 'src/app/utils/constant';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/root.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-tutorials-modal',
  templateUrl: './tutorials-modal.component.html',
  styleUrls: ['./tutorials-modal.component.scss'],
})
export class TutorialsModalComponent implements OnInit, OnDestroy {
  private _unsubscriber$: Subject<void> = new Subject();
  public step: number;
  public totalStep: number;
  public totalImg: string[];
  public texts: string[];
  public language: string;
  public imageLanguage: string;

  constructor(
    private _store: Store<RootState>,
    private _modalService: ModalService
  ) {
    this.step = 0;
    this.totalStep = 0;
    this.totalImg = [];
    this.texts = [];
    this.language = '';
    this.imageLanguage = '';
  }

  ngOnInit(): void {
    this._getLanguage();
    this._checkBrowser();
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next();
    this._unsubscriber$.unsubscribe();
  }

  private _checkBrowser(): void {
    this._store
      .select(selectBrowser)
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((browser: string) => {
        switch (browser) {
          case Constant.BROWSER.CH:
            this.totalStep = 3;
            this.totalImg = Constant[this.imageLanguage].CH;
            this.texts = Constant.TUTORIAL_STEPS.CH;
            break;
          case Constant.BROWSER.ED:
            this.totalStep = 3;
            this.totalImg = Constant[this.imageLanguage].ED;
            this.texts = Constant.TUTORIAL_STEPS.ED;
            break;

          case Constant.BROWSER.FF:
            this.totalStep = 2;
            this.totalImg = Constant[this.imageLanguage].FF;
            this.texts = Constant.TUTORIAL_STEPS.FF;
            break;

          case Constant.BROWSER.OP:
            this.totalStep = 2;
            this.totalImg = Constant[this.imageLanguage].OP;
            this.texts = Constant.TUTORIAL_STEPS.OP;
            break;

          case Constant.BROWSER.SA:
            this.totalStep = 8;
            this.totalImg = Constant[this.imageLanguage].SA;
            this.texts = Constant.TUTORIAL_STEPS.SA;
            break;
          default:
            break;
        }
      });
  }

  public incrementedDecrementedStep(step: number) {
    if (step) {
      if (this.step !== this.totalStep - 1) {
        this.step++;
      }
    } else if (this.step) {
      this.step--;
    }
  }

  public reload() {
    location.reload();
  }

  public closeModal() {
    this._modalService.hide();
  }

  private _getLanguage() {
    this.language = localStorage.getItem('lang').toUpperCase();
    this.imageLanguage = 'TUTORIAL_IMG_' + this.language;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GetConfigurationService } from '../services/get-configuration/get-configuration.service';
import { rootAction } from './root.action';
import { throwError } from 'rxjs';
import { DataParamsInterface } from './interface/data-params.interface';
import { SpinnerService } from '../services/spinner/spinner.service';
import { IsGroupalService } from '../services/is-groupal/is-groupal.service';

@Injectable()
export class RootEffect {
  constructor(
    private actions$: Actions,
    private _configurationService: GetConfigurationService,
    private _isGroupalService: IsGroupalService,
    private _spinnerService: SpinnerService
  ) {}

  fetchDataParams = createEffect(() =>
    this.actions$.pipe(
      ofType(rootAction.fetchDataParams),
      switchMap(({ params }) => {
        this._spinnerService.show();
        return this._configurationService.getDataParams(params).pipe(
          map((res: DataParamsInterface) => {
            this._spinnerService.hide();
            return rootAction.setDataParams({ dataParams: res });
          }),
          catchError((err) => {
            this._spinnerService.hide();
            return throwError(err);
          })
        );
      })
    )
  );

  fetchGroupRooms = createEffect(() =>
    this.actions$.pipe(
      ofType(rootAction.fetchGroupRooms),
      switchMap(({ room }) => {
        this._spinnerService.show();
        return this._isGroupalService.getGroupalRooms$(room).pipe(
          map((res: DataParamsInterface[]) => {
            this._spinnerService.hide();
            return rootAction.setGroupRooms({ roomData: res });
          }),
          catchError((err) => {
            this._spinnerService.hide();
            return throwError(err);
          })
        );
      })
    )
  );
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../store/root.state';
import { Constant } from '../../utils/constant';
import { rootAction } from '../../store/root.action';

@Injectable()
export class GetBrowserService {
  constructor(private _store: Store<RootState>) {}

  public getBrowser(): void {
    if (navigator.userAgent.indexOf(Constant.BROWSER.ED) !== -1) {
      const version = parseInt(
        navigator.userAgent.substr(navigator.userAgent.lastIndexOf('/') + 1),
        0
      );
      if (version <= 18) {
        this._store.dispatch(
          rootAction.setBrowserName({
            browser: Constant.BROWSER.OLD_ED,
          })
        );
      } else {
        this._store.dispatch(
          rootAction.setBrowserName({
            browser: Constant.BROWSER.ED,
          })
        );
      }
    } else if (
      (navigator.userAgent.indexOf(Constant.BROWSER.OP) ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      this._store.dispatch(
        rootAction.setBrowserName({
          browser: Constant.BROWSER.OP,
        })
      );
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.CH) !== -1) {
      this._store.dispatch(
        rootAction.setBrowserName({
          browser: Constant.BROWSER.CH,
        })
      );
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.iOSCH) !== -1) {
      this._store.dispatch(
        rootAction.setBrowserName({
          browser: Constant.BROWSER.iOSCH,
        })
      );
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.SA) !== -1) {
      this._store.dispatch(
        rootAction.setBrowserName({
          browser: Constant.BROWSER.SA,
        })
      );
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.FF) !== -1) {
      this._store.dispatch(
        rootAction.setBrowserName({
          browser: Constant.BROWSER.FF,
        })
      );
    } else if (navigator.userAgent.indexOf(Constant.BROWSER.IE) !== -1) {
      this._store.dispatch(
        rootAction.setBrowserName({
          browser: Constant.BROWSER.IE,
        })
      );
    } else {
      this._store.dispatch(
        rootAction.setBrowserName({
          browser: Constant.BROWSER.UNK,
        })
      );
    }

    if (navigator.userAgent.indexOf(Constant.SO.IOS) !== -1) {
      this._store.dispatch(
        rootAction.setSo({
          so: Constant.SO.IOS_TEXT,
        })
      );
    } else if (navigator.userAgent.indexOf(Constant.SO.ANDROID) !== -1) {
      this._store.dispatch(
        rootAction.setSo({
          so: Constant.SO.ANDROID,
        })
      );
    } else {
      this._store.dispatch(
        rootAction.setSo({
          so: Constant.SO.DESKTOP,
        })
      );
    }
  }

  public checkBrowserWebRTC(): boolean {
    const webRTC = window.RTCPeerConnection;
    return !!webRTC;
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { initParticipant } from './participant.state';
import { participantAction } from './participant.action';
import { ParticipantInterface } from '../interface/participant.interface';

export const participantReducer = createReducer(
  initParticipant,
  on(participantAction.setParticipants, (state, { participants }) => ({
    ...state,
    participants,
    isSettingParticipants: true
  })),
  on(participantAction.setParticipantsSuccess, (state) => ({
    ...state,
    isSettingParticipants: false
  })),
  on(participantAction.setParticipantsFail, (state) => ({
    ...state,
    isSettingParticipants: false
  }))
);

export function participantReducers(
  state: ParticipantInterface | undefined,
  action: Action
) {
  return participantReducer(state, action);
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonFloatInterface } from '../../interfaces/button-float.interface';

declare var $: any;

@Component({
  selector: 'app-button-float',
  templateUrl: './button-float.component.html',
  styleUrls: ['./button-float.component.scss'],
})
export class ButtonFloatComponent implements OnInit {
  private _floatButtons: ButtonFloatInterface[];
  public isNotification: boolean;
  public totalNotifications: number;
  @Input() set floatButtons(value: ButtonFloatInterface[]) {
    this._floatButtons = value;
    this.isNotification = this._checkBadges();
    /*setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 1000);*/
  }

  @Output() clickButton: EventEmitter<ButtonFloatInterface> = new EventEmitter<
    ButtonFloatInterface
  >();

  constructor() {
    this.totalNotifications = 0;
  }

  ngOnInit(): void {}

  get floatButton(): ButtonFloatInterface[] {
    return this._floatButtons;
  }

  private _checkBadges(): boolean {
    let isBadges = false;
    this.totalNotifications = 0;
    this._floatButtons.forEach((button) => {
      if (button.badge) {
        this.totalNotifications += button.badge;
        isBadges = true;
      }
    });
    if (this.totalNotifications === 0) {
      this.isNotification = false;
    }

    return isBadges;
  }

  clickOnButton(button: ButtonFloatInterface) {
    button.badge = null;
    this.totalNotifications = 0;
    this._checkBadges();
    this.clickButton.emit(button);
  }
}

import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { ToastComponent } from './toast/toast.component';
import { delay } from 'rxjs/operators';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _toast: ComponentFactory<ToastComponent>;
  private _toastRef: ComponentRef<ToastComponent>;

  constructor(private _factoryResolver: ComponentFactoryResolver) {}

  public show(
    text: string,
    type: string,
    closed: boolean,
    view: ViewContainerRef,
    translationParams?: any
  ) {
    if (!this._toastRef) {
      this._toast = this._factoryResolver.resolveComponentFactory(
        ToastComponent
      );
      this._toastRef = view.createComponent(this._toast);
      this._toastRef.instance.text = text;
      this._toastRef.instance.type = type;
      this._toastRef.instance.close = closed;
      this._toastRef.instance.closed.pipe(delay(300)).subscribe(() => {
        this._closed();
      });
      if (translationParams) {
        this._toastRef.instance.translationParams = translationParams;
      }
      if(this._toastRef.instance.close){
        timer(5 * 1000).subscribe(() => {
          this._toastRef.instance.closeToast();
        });
      }
    } else {
      this._toastRef.instance.text = text;
      this._toastRef.instance.type = type;
      if (translationParams) {
        this._toastRef.instance.translationParams = translationParams;
      }
    }
  }

  private _closed() {
    this._toastRef.destroy();
    this._toastRef = null;
  }
}

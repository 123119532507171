import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @Input() text: string;
  @Input() type: string;
  @Input() close: boolean;
  @Input() translationParams: any;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('toast') closeStyle: ElementRef;

  constructor(private render: Renderer2) {}

  ngOnInit(): void {}

  closeToast() {
    this.closed.emit(true);
    this.render.addClass(this.closeStyle.nativeElement, 'close');
  }
}

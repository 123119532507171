import { createAction, props } from '@ngrx/store';
import { ConfigAppInterface } from '../interface/config-app.interface';
import { DetailOfRoomInterface } from '../../interfaces/detail-of-room.interface';

const setData = createAction(
  '[jitsi] setData',
  props<{ data: ConfigAppInterface }>()
);
const fetchInternalDetailConfig = createAction(
  '[] fetchInternalDetailConfig',
  props<{ room: string }>()
);

const setInternalDataDetail = createAction(
  '[jitsi] setInternalDataDetail',
  props<{ data: DetailOfRoomInterface }>()
);
const fetchConfigApp = createAction('[] fetchConfigApp');

const setConditions = createAction(
  '[App Config] accept conditions',
  props<{ accepted: boolean }>()
);

export const configAppActions = {
  fetchConfigApp,
  setData,
  setConditions,
  fetchInternalDetailConfig,
  setInternalDataDetail,
};

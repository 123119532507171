import { ScreenInformationInterface } from '../interfaces/screen-information.interface';
import { ScreenInformationModel } from './ScreenInformation.model';

export class AppointmentDataModel {
  appointmentDate: string;
  name: string;
  serviceName: string;
  lastAppointmentDateCalled: string;
  averageWaitingTime: number;
  proof: string;
  pollUrl: string;
  proofUrl: string;
  advanceTime: number;
  delayTime: number;
  screenInformation: ScreenInformationInterface[];

  constructor() {
    this.appointmentDate = '';
    this.name = null;
    this.serviceName = '';
    this.lastAppointmentDateCalled = '';
    this.averageWaitingTime = 0;
    this.proof = '';
    this.pollUrl = '';
    this.proofUrl = '';
    this.advanceTime = 0;
    this.delayTime = 0;
    this.screenInformation = [new ScreenInformationModel()];
  }
}

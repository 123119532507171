import { Injectable } from '@angular/core';
import construct = Reflect.construct;
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TextService } from '../../services/text/text.service';
import { textAction } from './text.action';
import { filter, map, switchMap } from 'rxjs/operators';
import { TextsInterface } from '../../interfaces/texts-interface';
import { Store } from '@ngrx/store';
import { selectAllText, selectRootText } from './text.selector';

@Injectable()
export class TextEffect {
  constructor(
    private action$: Actions,
    private _textService: TextService,
    private _store: Store
  ) {}

  fetchAllText = createEffect(() =>
    this.action$.pipe(
      ofType(textAction.fetchAllTexts),
      switchMap(({ group, location, lang }) => {
        return this._textService.getAllText(group, location, lang).pipe(
          map((res: TextsInterface[]) => {
            return textAction.setAllTexts({
              texts: res,
            });
          })
        );
      })
    )
  );

  filterText = createEffect(() =>
    this.action$.pipe(
      ofType(textAction.filterText),
      switchMap(({ key }) => {
        return this._store.select(selectAllText).pipe(
          map((res: TextsInterface[]) => {
            const textFilter: TextsInterface = res.filter(
              (item): TextsInterface => {
                if (item.txtKey === key) {
                  return item;
                } else {
                  return null;
                }
              }
            )[0];
            return textAction.setText({
              text: textFilter,
            });
          })
        );
      })
    )
  );
}

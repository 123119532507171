<div id="language-selector">
  <div class="btn-group">
    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <!-- Cargar data dinámicamente -->
      {{selected | translate}}
    </button>
    <div class="dropdown-menu">
      <span  *ngFor="let item of languages">
        <span class="dropdown-item" (click)="changeLanguage(item)">  {{item.toLowerCase() | translate}}</span>
      </span>

    </div>
  </div>
</div>

<div class="cnt-share-file" #onDropStyle>
  <div class="wrapper-send-file" *ngIf="!showDragAndDrop; else dragAndDrop">
    <h4>{{ 'filesSentByMe' | translate }}</h4>

    <ul class="send-list">
      <li *ngFor="let file of listFilesShared">
        <ng-template
          [ngIf]="
            (file.participantType === typeUser && file.id === idUserFile) ||
            (file.participantType === typeUser && typeUser === 'ORGANIZER')
          "
        >
          <span class="material-icons">
            insert_drive_file
          </span>
          <span class="name-file">
            {{ file.name }}
          </span>
          <span class="material-icons" (click)="deletedFileShare(file)">
            delete
          </span>
        </ng-template>
      </li>
    </ul>

    <button>
      <span class="material-icons">
        cloud_upload
      </span>
      <span (click)="showDragAndDrop = true; filesList = []">
        {{ 'sendFile' | translate }}
      </span>
    </button>
  </div>

  <ng-template #dragAndDrop>
    <div
      class="wrapper-drag-drop"
      (drop)="dropHandler($event)"
      (dragover)="dragIn($event)"
    >
      <div class="icon-upload">
        <span class="material-icons">
          cloud_upload
        </span>
      </div>

      <div class="files-ready">
        <div *ngFor="let file of filesList">
          <span class="material-icons">
            insert_drive_file
          </span>
          <span>{{ file.name }}</span>
        </div>
      </div>

      <div class="message">
        <span> {{ 'selectFilesMsg' | translate }} </span>
        <span> {{ 'fileLimit' | translate }} </span>
      </div>

      <div class="wrapper-button-select">
        <label class="btn-blue" for="files" id="label">{{
          'selectFile' | translate
        }}</label>
        <input
          type="file"
          id="files"
          (change)="handleFiles(($event.target?.files)[0])"
          (click)="sendNotification()"
        />
      </div>
      <button (click)="uploadFiles()" *ngIf="filesList.length > 0" class="flat">
        <span> {{ 'shareFiles' | translate }} </span>
      </button>
    </div>
  </ng-template>

  <div class="wrapper-receiver-file" *ngIf="!showDragAndDrop">
    <h4>{{ 'receivedFiles' | translate }}</h4>

    <ul class="receiver-list">
      <li *ngFor="let file of listFilesShared">
        <ng-template
          [ngIf]="
            file.participantType !== typeUser ||
            (typeUser !== 'ORGANIZER' &&
              file.participantType === typeUser &&
              file.id !== idUserFile)
          "
        >
          <span class="material-icons">
            insert_drive_file
          </span>
          <span class="name-file" (click)="downloadFile(file)">
            {{ file.name }}
          </span>
          <span class="name-uploader">
            ({{
              (file.uploaderName ? file.uploaderName : 'assistant') | translate
            }})
          </span>
        </ng-template>
      </li>
    </ul>

    <button (click)="downloadAll()">
      <span class="material-icons">
        cloud_download
      </span>
      <span>{{ 'downloadFiles' | translate }}</span>
    </button>
  </div>

  <span class="note" *ngIf="!showDragAndDrop">
    * {{ 'filesAvailabilityMsg' | translate }}
  </span>
</div>

import { createReducer, on, Action } from '@ngrx/store';
import { initStateCheckin } from './checkin.state';
import { checkinActions } from './checkin.action';
import { CheckinInterface } from '../interface/checkin.interface';

const reducer = createReducer(
  initStateCheckin,
  on(checkinActions.setIsCheckinCompleted, (state, { isCheckinCompleted }) => ({
    ...state,
    isCheckinCompleted,
  })),
  on(checkinActions.setIsCheckinProblems, (state, { isCheckinProblems }) => ({
    ...state,
    isCheckinProblems,
  })),
  on(checkinActions.setIsDoctorOnCall, (state, { isDoctorOnCall }) => ({
    ...state,
    isDoctorOnCall,
  })),
  on(
    checkinActions.setIsDisabledCheckinButton,
    (state, { isDisabledCheckinButton }) => ({
      ...state,
      isDisabledCheckinButton,
    })
  )
);

export function checkinReducer(
  state: CheckinInterface | undefined,
  action: Action
) {
  return reducer(state, action);
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PARTICIPANT, ParticipantState } from './participant.state';
import { ParticipantInterface } from '../interface/participant.interface';

export const selectRootParticipant = createFeatureSelector<
  ParticipantState,
  ParticipantInterface
>(PARTICIPANT);

export const selectParticipant = createSelector(
  selectRootParticipant,
  (state) => state.participants
);

export const isSettingParticipants = createSelector(
  selectRootParticipant,
  (state) => state.isSettingParticipants
);

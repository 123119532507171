import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CENTER, CenterState } from './center.state';
import { CenterResponseInterface } from '../interface/center-response.interface';

export const selectCenter = createFeatureSelector<
  CenterState,
  CenterResponseInterface
>(CENTER);

export const selectBackground = createSelector(
  selectCenter,
  (state) => state.color
);

export const selectUrlLogo = createSelector(
  selectCenter,
  (state) => state.logo
);

export const selectLanguage = createSelector(
  selectCenter,
  (state) => state.languages
);

import { createAction, props } from '@ngrx/store';

const putDoCheckin = createAction('[jisti] putDoCheckin');
const putDoProblems = createAction('[jisti] putDoProblems');
const putDoPteCheckin = createAction('[jitsi] putDoCheckin')

const setIsCheckinCompleted = createAction(
  '[jisti] setIsCheckinCompleted',
  props<{ isCheckinCompleted: boolean }>()
);

const setIsCheckinProblems = createAction(
  '[jisti] setIsCheckinProblems',
  props<{ isCheckinProblems: boolean }>()
);

const setIsDoctorOnCall = createAction(
  '[jisti] setIsDoctorOnCall',
  props<{ isDoctorOnCall: boolean }>()
);

const setIsDisabledCheckinButton = createAction(
  '[jisti] setIsDisabledCheckinButton',
  props<{ isDisabledCheckinButton: boolean }>()
);

export const checkinActions = {
  putDoCheckin,
  putDoProblems,
  putDoPteCheckin,
  setIsCheckinCompleted,
  setIsCheckinProblems,
  setIsDoctorOnCall,
  setIsDisabledCheckinButton,
};

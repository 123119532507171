import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeadersService {

  constructor() { }

  private getAuthToken(): string {
    return 'Basic YWRtaW46V2oyNGFFNGZOOTQ4UkZBYg==';
  }

  getHeaders(): HttpHeaders{
    const token = this.getAuthToken();
    return new HttpHeaders({
      Authorization: token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*/*',
      Accept: '*/*',
    })
  }
}

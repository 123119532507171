import { Injectable, RendererFactory2 } from '@angular/core';
import { Spinner } from '../extended-spinner/spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerBlackService extends Spinner {
  constructor(renderFactory: RendererFactory2) {
    super(renderFactory, 'cnt-spinner-black', 'spinner-black');
  }

  public showWithText(text: string): void {
    super.show(text);
  }
}

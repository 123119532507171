import { createAction, props } from '@ngrx/store';

export const setParticipants = createAction(
  '[jitsi] set participants list',
  props<{ participants: string[] }>()
);

export const setParticipantsSuccess = createAction(
  '[jitsi] set participants list success'
);

export const setParticipantsFail = createAction(
  '[jitsi] set participants list fail'
);

export const participantAction = {
  setParticipants,
  setParticipantsSuccess,
  setParticipantsFail
};
